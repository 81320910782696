import React, { useState, useEffect } from 'react';
import { useGoogleLogin, useGoogleOneTapLogin } from '@react-oauth/google';
import { getCookie, setCookie } from '../../utils/Utils';
import { baseURLs } from '../../utils/Constants';
import axios from 'axios';
import { Button } from '../../components/Component';
import GoogleIcon from "../../images/google_icon.svg";
import { Spinner } from 'reactstrap';

const GoogleLoginButton = ({ buttonText, loadingGoogleLogin, setLoadingGoogleLogin, setFormError, country, ...props }) => {
  const [googleLoginClicked, setGoogleLoginClicked] = useState(false);

  const responseGoogle = async (response) => {
    setLoadingGoogleLogin(true);
    let promo_code = getCookie('promo_code') || null;
    
    try {
      const accessToken = response.access_token || null;
      const credential = response.credential || null;

      axios.post(baseURLs.API_URL + "/auth/google-login", {
        token: credential,
        access_token: accessToken,
        country: country,
        promo_code: promo_code,
        source: "web"
      })
      .then((response) => {
        const { access_token, user_info } = response.data.data;

        let expirationDate = new Date();
        expirationDate.setTime(expirationDate.getTime() + (90 * 24 * 60 * 60 * 1000));

        setCookie('access_token', access_token, expirationDate);
        localStorage.setItem('userInfo', JSON.stringify(user_info));
        localStorage.setItem('justLoggedIn', true);
        window.location = `${process.env.PUBLIC_URL}/my-businesses`;

      }).catch((error) => {
        let errorResponse = error.response?.data ?? '';

        let errorMessage = "Login with Google failed";
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setGoogleLoginClicked(false);
        setLoadingGoogleLogin(false);
        setFormError(errorMessage);
      });

    } catch (error) {
      setLoadingGoogleLogin(false);
      setFormError("Login with Google failed");
    }
  };

  const updateGoogleLoginStatus = () => {
    setFormError("");
    setLoadingGoogleLogin(true);
  };

  // Handling login failure
  const handleLoginFailure = (error) => {
    console.log('Google login failure', error);

    if (['popup_failed_to_open','popup_closed'].includes(error.error)) {
      setFormError("Google login was closed. Please try again.");
    } else {
      setFormError("Login with Google failed, Try again.");
    }

    setLoadingGoogleLogin(false);
    setGoogleLoginClicked(false);
  };

  // Google Login using useGoogleLogin hook
  const login = useGoogleLogin({
    onSuccess: responseGoogle,
    onError: handleLoginFailure,
    onNonOAuthError: handleLoginFailure,
    scope: 'openid email profile', // Ensures the ID token is returned
    flow: 'implicit',
  });

  // One Tap Login using useGoogleOneTapLogin hook (only if the button is not clicked)
  useGoogleOneTapLogin({
    onSuccess: responseGoogle,
    onError: handleLoginFailure,
    scope: 'openid email profile', // Ensures the ID token is returned
    flow: 'implicit',
    disabled: googleLoginClicked
  });

  return (
    <>
      {loadingGoogleLogin ? (
        <Button size="lg" outline className="btn-block mt-4" color="light">
          <Spinner size="sm" color="dark" /> 
        </Button>
      ) : (
        <Button
          size="lg"
          onClick={() => {
            setGoogleLoginClicked(true); // Set when the button is clicked
            updateGoogleLoginStatus();
            login();
          }}
          disabled={loadingGoogleLogin}
          outline
          className="btn-block mt-4"
          color="light"
        >
          <img
            className="google-icon"
            alt="google_icon"
            src={GoogleIcon}
            style={{ height: '18px', width: '18px', margin: '0 10px' }}
          />
          <span>{buttonText}</span>
        </Button>
      )}
    </>
  );
};

export default GoogleLoginButton;
