import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { DropdownToggle, DropdownMenu, UncontrolledDropdown, DropdownItem } from "reactstrap";
import SimpleBar from "simplebar-react";
import menu from "../menu/MenuData";
import Menu from "../menu/Menu";
import { Button, Icon } from "../../components/Component";
import { Link } from "react-router-dom/cjs/react-router-dom";

const Sidebar = ({ fixed, theme, className, visibility, sidebarToggle, mobileView, ...props }) => {
  const [menuLinks, setMenuLinks] = useState([]); 
  const [businessID, setBusinessID] = useState("");
  const [currentBusinessID, setCurrentBusinessID] = useState(localStorage.getItem('current_business_id'));
  const [showFAB, setShowFAB] = useState({
    create_invoice: false,
    add_product: false,
    record_expenses: false,
  });

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  const classes = classNames({
    "nk-aside toggle-screen-lg": true,
    "content-active": visibility,
    [`is-light`]: theme === "white",
    [`is-${theme}`]: theme !== "white" && theme !== "light",
    [`${className}`]: className,
  });

  useEffect(() => {
    const handleStorageChange = () => {
      const newBusinessID = localStorage.getItem('current_business_id');
      if (newBusinessID !== currentBusinessID) {
        setCurrentBusinessID(newBusinessID);
      }
    };
  
    // Initially set currentBusinessID when component mounts
    setCurrentBusinessID(localStorage.getItem('current_business_id'));
  
    window.addEventListener("storage", handleStorageChange);
  
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [currentBusinessID]);
  
  useEffect(() => {
    let newMenuLinks = [...menu];
    const currentBusinessID = localStorage.getItem('current_business_id');
    const businesses = JSON.parse(localStorage.getItem('my_businesses'));
    
    if (businesses) {
      const business = businesses?.find((business) => business.business_id == currentBusinessID);
      const permissions = business?.permissions;
  
      if (business?.currency !== "GHS") {
        newMenuLinks = newMenuLinks.filter((item) => item.text !== 'Payouts');
      }
  
      if (permissions && business.role === 'team-member') {
        newMenuLinks = menu.filter((item) => {
          const hasPermission = item.permission.some((perm) =>
            permissions.some((userPerm) => userPerm.permission === perm)
          );
  
          if (item.subMenu && hasPermission) {
            item.subMenu = item.subMenu.filter((subItem) =>
              !subItem.permission ||
              subItem.permission.some((perm) =>
                permissions.some((userPerm) => userPerm.permission === perm)
              )
            );
          }
  
          return hasPermission;
        });
      }
  
      const _showCreateInvoice = business.role === 'owner' || permissions.some((perm) => perm.permission === 'record_sales');
      const _showAddProduct = business.role === 'owner' || permissions.some((perm) => perm.permission === 'add_products');
      const _showRecordExpenses = business.role === 'owner' || permissions.some((perm) => perm.permission === 'add_expenses');
  
      setShowFAB({
        create_invoice: _showCreateInvoice,
        add_product: _showAddProduct,
        record_expenses: _showRecordExpenses,
      });
  
      setBusinessID(currentBusinessID);
      setMenuLinks(newMenuLinks);
    }
  }, [currentBusinessID]);

  return (
    <>
      <div className={classes}>
        <SimpleBar className="nk-sidebar-menu">
          <Menu sidebarToggle={sidebarToggle} mobileView={mobileView} menu={menuLinks} />
        </SimpleBar>
      </div>

      {
        (showFAB.create_invoice || showFAB.add_product || showFAB.record_expenses) &&
        <UncontrolledDropdown isOpen={dropdownOpen} toggle={toggleDropdown} direction="up">
          <DropdownToggle className="dropdown-toggle btn btn-primary btn-round btn-icon text-white btn-fab" color="none">
            <Icon name="plus-fill-c"></Icon>
          </DropdownToggle>
          <DropdownMenu className="mb-3" end>
            <ul className="link-list-opt no-bdr">
              {
                showFAB.create_invoice &&
                <li>
                  <Link to={`${process.env.PUBLIC_URL}/create-invoice-receipt/b/${currentBusinessID}`} onClick={toggleDropdown}>
                    <Icon name="coins"></Icon>
                    <span>Create Invoice</span>
                  </Link>
                </li>
              }

              {
                showFAB.add_product && 
                <li>
                  <Link to={`${process.env.PUBLIC_URL}/products/b/${currentBusinessID}/add`} onClick={toggleDropdown}>
                    <Icon name="package"></Icon>
                    <span>Add Product</span>
                  </Link>
                </li>
              }

              {
                showFAB.record_expenses &&
                <li>
                  <Link to={`${process.env.PUBLIC_URL}/expenses/b/${currentBusinessID}/add`} onClick={toggleDropdown}>
                    <Icon name="pie"></Icon>
                    <span>Record Expenses</span>
                  </Link>
                </li>
              }
            </ul>
          </DropdownMenu>
        </UncontrolledDropdown>
      }
    </>
  );
};
export default Sidebar;
