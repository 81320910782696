import React, { useState, useEffect } from "react";
import { Empty, Select } from 'antd';
import { baseURLs } from '../../../utils/Constants';
import axios from 'axios';
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import moment from 'moment';
import { getAxiosHeaders, getQueryParams } from "../../../utils/Utils";
import { Badge, 
  DropdownToggle, 
  DropdownMenu, 
  Card, 
  UncontrolledDropdown, 
  DropdownItem, 
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter } from "reactstrap";
import {
  Block,
  Icon,
  Button
} from "../../../components/Component";
import {
  AlertModal,
  SuccessModal,
  LoadingModal
} from "../AlertModals";
import { TableLoader } from "../../../utils/Loaders";
import { PaginationWithOnclick } from "./Pagination";
import { offlineDB } from "../../../utils/OfflineDB";

export const SalesTable = ({ history, businessID, currency, showFilter = true, showPagination = true, showStats = true, salesData = null, page, title = null, ...props }) => {
  const [tableData, setTableData] = useState(salesData);
  const [loading, setLoading] = useState(true);
  const [alertCustomer, setAlertCustomer] = useState(true);
  const [totalPages, setTotalPages] = useState(1);
  const [hasFilters, setHasFilters] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const [checkedBoxes, setCheckedBoxes] = useState([]);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [filters, setFilters] = useState({
    business: businessID,
    page: 1,
    customer_search: '',
    phone_search: '',
    sales_id_search: '',
    date_range: null,
    dateRangeStart: null,
    dateRangeEnd: null,
    type: 'all',
    source: 'all'
  });
  const [downloadLink, setDownloadLink] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [activeModal, setActiveModal] = useState(null);
  const [totalOfflineSales, setTotalOfflineSales] = useState(0);
  const [totalOfflineAmountPaid, setTotalOfflineAmountPaid] = useState(0);
 
  const toggleModal = (modal) => {
    if (activeModal === modal) {
      setActiveModal(null);
    } else {
      setActiveModal(modal);
    }
  };

  const onInputChange = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  const onDateChange = (date, type) => {
    let params = filters;

    if(!moment(date).isValid()){
      params.date_range = null;
      params.dateRangeStart = null;
      params.dateRangeEnd = null;
      setFilters({...params});
      return;
    }

    if(type === 'from') {
      params.dateRangeStart = date;  
      setFromDate(date);  
    } else {
      params.dateRangeEnd = date;
      setToDate(date);  
    }

    if(moment(params.dateRangeStart).isValid() && moment(params.dateRangeEnd).isValid()) {
      params.date_range = `${moment(params.dateRangeStart).format("YYYY-MM-DD")},${moment(params.dateRangeEnd).format("YYYY-MM-DD")}`;
      setFilters({...params});      
    }
  }

  const onSelectChange = (value, name) => {
    setFilters({ ...filters, [name]: value });
  };

  const selectAll = () => {
    setCheckAll(prevCheckAll => {
      if (prevCheckAll) {
        setCheckedBoxes([]);
      } else {
        const allIDs = tableData.sales.map(item => item.sale_id);
        setCheckedBoxes(allIDs);
      }
      return !prevCheckAll;
    });
  }

  const handleCheckboxChange = (value) => {
    setCheckedBoxes(prevBoxes => {
      if (prevBoxes.includes(value)) {
          return prevBoxes.filter(id => id !== value);
      } else {
          return [...prevBoxes, value];
      }
    });
    if (checkAll) {
        setCheckAll(false);
    }
  };

  const currentUrl = (filters) => {
    let customer_search = filters.customer_search.length > 0 ? `&cs=${filters.customer_search}` : ``;
    let phone_search = filters.phone_search.length > 0 ? `&ps=${filters.phone_search}` : ``;
    let sales_id_search = filters.sales_id_search.length > 0 ? `&ss=${filters.sales_id_search}` : ``;
    let date_range = filters.date_range !== null ? `&r=${filters.date_range}` : ``;
    let type = filters.type !== null ? `&t=${filters.type}` : ``;
    let source = filters.source !== null ? `&so=${filters.source}` : ``;
    
    if( (customer_search !== '' ||
    phone_search !== '' ||
    sales_id_search !== '' ||
    date_range !== '' ||
    filters.type !== 'all' ||
    filters.source !== 'all') && !hasFilters) {
      setHasFilters(true)
    }

    let params = `${customer_search}${phone_search}${sales_id_search}${date_range}${type}${source}`;
    let url = `${process.env.PUBLIC_URL}${window.location.pathname}?p=${filters.page}${params}`;
    history.replace(url);
  }

  const loadNextPage = (page) => {
    let params = filters;
    params.page = page;
    setFilters({...params});
    getSales(params)
  }

  const resetFilter = () => {
    let params = {
      business: businessID,
      page: 1,
      customer_search: '',
      phone_search: '',
      sales_id_search: '',
      date_range: null,
      dateRangeStart: null,
      dateRangeEnd: null,
      type: 'all',
      source: 'all'
    };
  
    toggleModal('filterModal');
    setHasFilters(false);
    setFromDate(null);
    setToDate(null);
    setFilters({ ...params });
    getSales(params);
  }

  const filterSales = () => {
    toggleModal('filterModal');
    setHasFilters(true);
    let params = filters;
    params.page = 1;
    setFilters({...params});
    getSales(filters);
  }

  const getSales = (filters) => {
    setLoading(true);

    axios.get(baseURLs.API_URL + "/sales", {
      params: {
        business_id: businessID,
        page: filters.page,
        customer: filters.customer_search,
        phone_number: filters.phone_search,
        sale_number: filters.sales_id_search,
        date_from: !moment(filters.dateRangeStart).isValid() ? null : moment(filters.dateRangeStart).format("YYYY-MM-DD"),
        date_to: !moment(filters.dateRangeEnd).isValid() ? null : moment(filters.dateRangeEnd).format("YYYY-MM-DD"),
        sale_status: filters.type,
        sale_origin: filters.source
      },
      headers: getAxiosHeaders().headers
    })
    .then((response) => {
      if (response.status === 204) {
        setTotalPages(0);
        setTableData({
          meta: {
            total_records: 0, 
            total_receipts: 0, 
            total_invoices: 0, 
            total_quotes: 0,
          }, 
          sales:[]
        });
      } else {
        let responseInfo = response.data;
        setTotalPages(Math.ceil(responseInfo.data.meta.total_records / 10));
        setTableData(responseInfo.data);
      }

      setLoading(false);
      currentUrl(filters);
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401){
          history.push(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          history.push(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          history.push(`${process.env.PUBLIC_URL}/unauthorized/b/${businessID}`);
          return;
        }

        if(error.response.status === 402){
          history.push(`${process.env.PUBLIC_URL}/subscription/b/${businessID}`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setTotalPages(0);
        setTableData({
          meta: {
            total_records: 0, 
            total_receipts: 0, 
            total_invoices: 0, 
            total_quotes: 0,
          }, 
          sales:[]
        });

        setLoading(false);
        setErrorMessage(errorMessage);
      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  const cancelSalesByIDs = () => {
    toggleModal('cancelLoadingModal');

    axios.put(baseURLs.API_URL + "/sales/cancel/by-ids", {
      business_id: businessID,
      sales: JSON.stringify(checkedBoxes),
      alert_customer: alertCustomer ? "yes" : "no"
    }, getAxiosHeaders())
    .then((response) => {
      let responseInfo = response.data;
      let successfulIDs = responseInfo.data.successful_sale_ids;
      let failedIDs = responseInfo.data.failed_sale_ids;

      if(successfulIDs.length > 0 && failedIDs.length === 0){
        getSales(filters);
        toggleModal('successCancelModal');

      } else if(successfulIDs.length > 0 && failedIDs.length > 0) {
        getSales(filters);
        setErrorMessage(`${successfulIDs.length} ${successfulIDs.length > 1 ? `sales` : `sale`} were successfully cancelled and ${failedIDs.length} ${failedIDs.length > 1 ? `sales` : `sale`} failed to get cancel`);
        toggleModal('cancelErrorModal');

      } else {
        setErrorMessage(`Error canceling ${failedIDs.length > 1 ? `sales` : `sale`}. The selected ${failedIDs.length > 1 ? `sales were` : `sale was`} not found.`);
        toggleModal('cancelErrorModal');
      }

      setCheckedBoxes([]);
      setCheckAll(false);
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401){
          history.push(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          history.push(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          history.push(`${process.env.PUBLIC_URL}/unauthorized/b/${businessID}`);
          return;
        }

        if(error.response.status === 402){
          history.push(`${process.env.PUBLIC_URL}/subscription/b/${businessID}`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
        toggleModal('cancelErrorModal');
      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  const cancelSalesByFilter = () => {
    toggleModal('cancelLoadingModal');

    axios.put(baseURLs.API_URL + "/sales/cancel/by-filters", {
      business_id: businessID,
      alert_customer: alertCustomer ? "yes" : "no",
      customer: filters.customer_search,
      phone_number: filters.phone_search,
      sale_number: filters.sales_id_search,
      date_from: !moment(filters.dateRangeStart).isValid() ? null : moment(filters.dateRangeStart).format("YYYY-MM-DD"),
      date_to: !moment(filters.dateRangeEnd).isValid() ? null : moment(filters.dateRangeEnd).format("YYYY-MM-DD"),
      sale_status: filters.type,
      sale_origin: filters.source
    }, getAxiosHeaders())
    .then((response) => {
      getSales(filters);
      toggleModal('successCancelModal');
      setCheckAll(false);
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401){
          history.push(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          history.push(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          history.push(`${process.env.PUBLIC_URL}/unauthorized/b/${businessID}`);
          return;
        }

        if(error.response.status === 402){
          history.push(`${process.env.PUBLIC_URL}/subscription/b/${businessID}`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
        toggleModal('cancelErrorModal');
      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  const refundSalesByIDs = () => {
    toggleModal('refundLoadingModal');

    axios.put(baseURLs.API_URL + "/sales/refund/by-ids", {
      business_id: businessID,
      sales: JSON.stringify(checkedBoxes),
      alert_customer: alertCustomer ? "yes" : "no"
    }, getAxiosHeaders())
    .then((response) => {
      let responseInfo = response.data;
      let successfulIDs = responseInfo.data.successful_sale_ids;
      let failedIDs = responseInfo.data.failed_sale_ids;

      if(successfulIDs.length > 0 && failedIDs.length === 0){
        getSales(filters);
        toggleModal('successRefundModal');

      } else if(successfulIDs.length > 0 && failedIDs.length > 0) {
        getSales(filters);
        setErrorMessage(`${successfulIDs.length} ${successfulIDs.length > 1 ? `sales` : `sale`} were successfully refunded and ${failedIDs.length} ${failedIDs.length > 1 ? `sales` : `sale`} failed to get cancel`);
        toggleModal('refundErrorModal');

      } else {
        setErrorMessage(`Error refunding ${failedIDs.length > 1 ? `sales` : `sale`}. The selected ${failedIDs.length > 1 ? `sales were` : `sale was`} not found.`);
        toggleModal('refundErrorModal');
      }

      setCheckedBoxes([]);
      setCheckAll(false);
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401){
          history.push(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          history.push(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          history.push(`${process.env.PUBLIC_URL}/unauthorized/b/${businessID}`);
          return;
        }

        if(error.response.status === 402){
          history.push(`${process.env.PUBLIC_URL}/subscription/b/${businessID}`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
        toggleModal('refundErrorModal');
      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  const refundSalesByFilter = () => {
    toggleModal('refundLoadingModal');

    axios.put(baseURLs.API_URL + "/sales/refund/by-filters", {
      business_id: businessID,
      alert_customer: alertCustomer ? "yes" : "no",
      customer: filters.customer_search,
      phone_number: filters.phone_search,
      sale_number: filters.sales_id_search,
      date_from: !moment(filters.dateRangeStart).isValid() ? null : moment(filters.dateRangeStart).format("YYYY-MM-DD"),
      date_to: !moment(filters.dateRangeEnd).isValid() ? null : moment(filters.dateRangeEnd).format("YYYY-MM-DD"),
      sale_status: filters.type,
      sale_origin: filters.source
    }, getAxiosHeaders())
    .then((response) => {
      getSales(filters);
      toggleModal('successRefundModal');
      setCheckAll(false);
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401){
          history.push(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          history.push(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          history.push(`${process.env.PUBLIC_URL}/unauthorized/b/${businessID}`);
          return;
        }

        if(error.response.status === 402){
          history.push(`${process.env.PUBLIC_URL}/subscription/b/${businessID}`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
        toggleModal('refundErrorModal');
      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  const handleSelectedSaleAction = (action) => {
    if(!checkAll && checkedBoxes.length === 0){
      toggleModal('noSelectionAlertModal');
      return;
    }

    action === 'refund' ? toggleModal('refundAlertModal') : toggleModal('cancelAlertModal');    
  }

  const handleSingleSelectSaleAction = (saleID, action) => {
    setCheckedBoxes([saleID]);

    setTimeout(() => {
      action === 'refund' ? toggleModal('refundAlertModal') : toggleModal('cancelAlertModal');          
    }, 500);
  }

  const downloadSales = () => {
    setErrorMessage("");
    toggleModal('loadingModal');
    
    axios.get(baseURLs.API_URL + "/sales/download", {
      params: {
        business_id: businessID,
        customer: filters.customer_search,
        phone_number: filters.phone_search,
        sale_number: filters.sales_id_search,
        date_from: !moment(filters.dateRangeStart).isValid() ? null : moment(filters.dateRangeStart).format("YYYY-MM-DD"),
        date_to: !moment(filters.dateRangeEnd).isValid() ? null : moment(filters.dateRangeEnd).format("YYYY-MM-DD"),
        sale_status: filters.type,
        sale_origin: filters.source
      },
      headers: getAxiosHeaders().headers
    })
    .then((response) => {
      if (response.status === 204) {
        setErrorMessage('No sales found');        
        toggleModal('alertModal');
      } else {
        let responseInfo = response.data;
        setDownloadLink(responseInfo.data.download_link);
        toggleModal('downloadModal');
      }

    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401){
          history.push(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          history.push(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          history.push(`${process.env.PUBLIC_URL}/unauthorized/b/${businessID}`);
          return;
        }

        if(error.response.status === 402){
          history.push(`${process.env.PUBLIC_URL}/subscription/b/${businessID}`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
        toggleModal('alertModal');

      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  useEffect(() => {
    setLoading(true);
    let url_string = window.location.href;
    let url = new URL(url_string);
    let queryParams = getQueryParams(url);

    let page = queryParams.hasOwnProperty('p') ? queryParams.p : 1;
    let customer_search = queryParams.hasOwnProperty('cs') ? queryParams.cs : '';
    let phone_search = queryParams.hasOwnProperty('ps') ? queryParams.ps : '';
    let sales_id_search = queryParams.hasOwnProperty('ss') ? queryParams.ss : '';
    let date_range = queryParams.hasOwnProperty('r') ? queryParams.r : null;
    let type = queryParams.hasOwnProperty('t') ? queryParams.t : 'all';
    let source = queryParams.hasOwnProperty('so') ? queryParams.so : 'all';
    let dateRangeStart = null;
    let dateRangeEnd = null;

    if(date_range !== null){
      let rangeSplit = date_range.split(',');
      dateRangeStart = new Date(rangeSplit[0]);
      dateRangeEnd = new Date(rangeSplit[1]);
      setFromDate(dateRangeStart);
      setToDate(dateRangeEnd);
    }

    let params = filters;
    params.page = page;
    params.customer_search = customer_search;
    params.phone_search = phone_search;
    params.sales_id_search = sales_id_search;
    params.date_range = date_range;
    params.dateRangeStart = dateRangeStart;
    params.dateRangeEnd = dateRangeEnd;
    params.type = type;
    params.source = source;

    setFilters(params)

    if(salesData == undefined || null || ""){
      getSales(params);
    }else{
      setTotalPages(Math.ceil(salesData.meta.total_records / 10));
      setLoading(false)
    }
  }, []);

  useEffect(() => {
    // Function to calculate total number of offline sales and total amount
    const fetchSalesData = async () => {
      try {
        // Retrieve all offline sales
        const offlineSales = await offlineDB.offline_sales.where('business_id').equals(businessID).toArray();
        
        // Calculate total number of offline sales
        setTotalOfflineSales(offlineSales.length);

      } catch (error) {
        console.error("Error fetching sales data:", error);
      }
    };

    fetchSalesData();
  }, []);

  
  return (
    <Block>
      {
        totalOfflineSales > 0 && 
        <div className="card-bordered card mb-2">
          <div className="card-inner py-lg-1">
            <div className="nk-help">
              <div className="nk-help-img m-0">
                <lord-icon
                  src="https://cdn.lordicon.com/akqsdstj.json"
                  trigger="loop"
                  delay="1000"
                  style={{width:"120px",height:"120px"}}>
                </lord-icon>
              </div>
              <div className="nk-help-text">
                <h5>Offline Sales Alert</h5>
                <p className="text-soft">You have <b>{totalOfflineSales} offline {totalOfflineSales > 1 ? `sales` : `sale`}</b> waiting to be uploaded.</p>
              </div>
              <div className="nk-help-action">
                <Link to={`${process.env.PUBLIC_URL}/sales/b/${businessID}/offline`}>
                  <button className="btn btn-outline-primary">
                    View Offline Sales
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      }

      {
        loading ?
        <div className="card-inner p-0">
          <TableLoader />
        </div>
        :
        <>
          {
            tableData.sales.length > 0 && showStats &&
            <div className="row g-gs mb-5">
              <div className="col-md-12">
                <Card className="card-bordered">
                  <div className="card-inner">
                    <div className="analytic-data-group analytic-ov-group g-3 justify-content-md-evenly ">
                      <div className="analytic-data text-md-center me-4">
                        <div className="title text-success">Receipts</div>
                        <div className="amount">{tableData.meta.total_receipts}</div>
                      </div>
                      <div className="btn-toolbar-sep me-3 ms-3 d-none d-sm-inline"></div>
                      <div className="analytic-data text-md-center me-4">
                        <div className="title text-warning">Invoices</div>
                        <div className="amount">{tableData.meta.total_invoices}</div>
                      </div>
                      <div className="btn-toolbar-sep me-3 ms-3 d-none d-sm-inline"></div>
                      <div className="analytic-data text-md-center me-4">
                        <div className="title text-muted">Quotes</div>
                        <div className="amount">{tableData.meta.total_quotes}</div>
                      </div>
                    </div>
                  </div>
                </Card>   
              </div>
            </div>
          }
          <Card className="card-bordered card-stretch">
            <div className="card-inner-group">
              <div className="card-inner">
                <div className="card-title-group">
                  <div className="card-title">
                    <h6 className="title">
                      {
                        title !== null ? 
                        title
                        : 
                        `${tableData.meta.total_records} ${tableData.meta.total_records > 1 ? `Records` : `Record`}`
                      }
                    </h6>
                  </div>
                  {
                    showFilter &&
                    <div className="card-tools me-n1">
                      <ul className="btn-toolbar">
                        <li>
                          <Button className="btn-icon btn-trigger" onClick={() => toggleModal('filterModal')} title="Filter">
                            { hasFilters && <div className="dot dot-primary"></div> }
                            <Icon name="search"></Icon>
                          </Button>
                          {
                            activeModal === "filterModal" &&
                            <Modal isOpen={true} toggle={() => toggleModal('filterModal')}>
                              <ModalHeader
                                toggle={() => toggleModal('filterModal')}
                                close={
                                  <button className="close" onClick={() => toggleModal('filterModal')}>
                                    <Icon name="cross" />
                                  </button>
                                }
                              >
                                Sales Filter
                              </ModalHeader>
                              <ModalBody>
                                <form>
                                  <div className="form-group">
                                    <label className="form-label" htmlFor="customer_search">
                                      Customer Name / Customer ID
                                    </label>
                                    <div className="form-control-wrap">
                                      <input type="text" onChange={onInputChange} name="customer_search" className="form-control form-control-lg" id="customer_search" defaultValue={filters.customer_search} />
                                    </div>
                                  </div>
                                  <div className="form-group">
                                    <label className="form-label" htmlFor="phone_search">
                                      Phone Number
                                    </label>
                                    <div className="form-control-wrap">
                                      <input type="text" onChange={onInputChange} name="phone_search" className="form-control form-control-lg" id="phone_search" defaultValue={filters.phone_search} />
                                    </div>
                                  </div>
                                  <div className="form-group">
                                    <label className="form-label" htmlFor="sales_id_search">
                                      Sale ID
                                    </label>
                                    <div className="form-control-wrap">
                                      <input type="number" onChange={onInputChange} name="sales_id_search" className="form-control form-control-lg" id="sales_id_search" defaultValue={filters.sales_id_search} />
                                    </div>
                                  </div>
                                  <div className="form-group">
                                    <label className="form-label" htmlFor="phone-no">
                                      Sale Source
                                    </label>
                                    <div className="form-control-wrap">
                                      <Select size="large"                          
                                        placeholder="Select source"
                                        defaultValue={filters.source}
                                        style={{ width: "100%" }} 
                                        name="source"
                                        onChange={(value) => onSelectChange(value, "source")}
                                        filterOption={(input, option) =>
                                          option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                          option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        options={[
                                          {value: "all", label: "All"},
                                          {value: "business", label: "Business"},
                                          {value: "storefront", label: "Storefront"},
                                        ]} 
                                        showSearch /> 
                                    </div>
                                  </div>
                                  <div className="form-group">
                                    <label className="form-label" htmlFor="phone-no">
                                      Sale Status
                                    </label>
                                    <div className="form-control-wrap">
                                    <Select size="large"                          
                                        placeholder="Select status"
                                        defaultValue={filters.type}
                                        style={{ width: "100%" }} 
                                        name="type"
                                        onChange={(value) => onSelectChange(value, "type")}
                                        filterOption={(input, option) =>
                                          option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                          option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        options={[
                                          {value: "all", label: "All"},
                                          {value: "receipt", label: "Receipts (Paid)"},
                                          {value: "invoice", label: "Invoices (Unpaid or Part-paid)"},
                                          {value: "quote", label: "Quotes"},
                                          {value: "cancelled", label: "Cancelled"},
                                          {value: "refunded", label: "Refunded"},
                                        ]} 
                                        showSearch /> 
                                    </div>
                                  </div>
                                  <div className="row mb-3">
                                    <div className="col-md-6">
                                      <div className="form-group">
                                        <label className="form-label">From Date</label>                          
                                        <div className="form-control-wrap">
                                          <DatePicker
                                            selected={fromDate}
                                            dateFormat="dd-MMM-yyyy"
                                            onChange={date => {
                                              onDateChange(date, 'from');
                                            }}
                                            
                                            showMonthDropdown
                                            showYearDropdown
                                            isClearable
                                            className="form-control form-control-lg date-picker"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="form-group">
                                        <label className="form-label">To Date</label>                          
                                        <div className="form-control-wrap">
                                          <DatePicker
                                            selected={toDate}
                                            dateFormat="dd-MMM-yyyy"
                                            onChange={date => {
                                              onDateChange(date, 'to');
                                            }}
                                            
                                            showMonthDropdown
                                            showYearDropdown
                                            isClearable
                                            className="form-control form-control-lg date-picker"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              </ModalBody>
                              <ModalFooter className="bg-lighter justify-content-center">
                                <Button color="primary" type="submit" onClick={(ev) => { ev.preventDefault(); filterSales();} } size="lg">
                                  Apply Filter
                                </Button>

                                {
                                  hasFilters &&
                                  <Button className="ms-3 text-muted" color="light" onClick={(ev) => { ev.preventDefault(); resetFilter();} } size="lg">
                                    Reset Filter
                                  </Button>
                                }
                              </ModalFooter>
                            </Modal>
                          }
                        </li>
                        <li className="btn-toolbar-sep"></li>
                        <li>
                          <Button className="btn-icon btn-trigger" title="Reload" onClick={() => {getSales(filters)}}>
                            <Icon name="redo"></Icon>
                          </Button>
                        </li>
                        <li className="btn-toolbar-sep"></li>
                        <li>
                          <Button className="btn-icon btn-trigger" onClick={downloadSales} title="Download Sales">
                            <Icon name="download-cloud"></Icon>
                          </Button>
                          { activeModal === "loadingModal" && <LoadingModal showModal={true} headerText={"Preparing Sales"} descriptionText={"Please wait while your sales are being prepared for download."} /> }
                          
                          { 
                            activeModal === "downloadModal" &&
                            <SuccessModal showModal={true} toggleModal={() => toggleModal('downloadModal')}
                              headerText={"Sales Ready"} descriptionText={"Sales CSV is ready for download."} 
                              leftButtonText={"Download"} leftButtonOnClick={() => {window.open(downloadLink, '_blank').focus(); toggleModal('downloadModal');}}
                            />
                          }
                          
                          {
                            activeModal === "alertModal" &&
                            <AlertModal showModal={true} toggleModal={() => toggleModal('alertModal')}
                              headerText={"Download Failed"} descriptionText={errorMessage}                       
                            />
                          }
                        </li>
                      </ul>
                    </div>
                  }
                </div>
              </div>          
              <div className="card-inner p-0">
                {
                  tableData.sales.length === 0 ?
                  <div className="text-center m-5">
                    <div className="price-plan-media"><Empty image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg" imageStyle={{height: 60}} description={false} /></div>
                    <div className="price-plan-info">
                      <h5 className="title fw-normal">No sales found</h5>
                      <span>Record a sale by sending an invoice, a quote, or a receipt to your customers.</span>
                    </div>
                    <div className="price-plan-action">
                      <Link to={`${process.env.PUBLIC_URL}/create-invoice-receipt/b/${businessID}`}>
                        <button className="btn btn-outline-primary">Create Invoice / Receipt</button>
                      </Link>                    
                    </div>
                  </div>
                  :
                  <>                   
                    <table className="table table-tranx">
                      <thead>
                        <tr className="tb-tnx-head">
                          <th className="tb-tnx-checkbox nk-tb-col-check">
                            <div className="custom-control custom-control-sm custom-checkbox">
                              <input
                                type="checkbox"
                                checked={checkAll}
                                onChange={selectAll}
                                className="custom-control-input table-checkbox"
                                id="select_all" />
                              <label className="custom-control-label" htmlFor="select_all"/>
                            </div>
                          </th>
                          <th className="tb-tnx-id"><span className="d-none d-sm-inline-block">#</span></th>
                          <th className="tb-tnx-info">
                            <span className="tb-tnx-desc d-none d-sm-inline-block">
                              <span>Customer</span>
                            </span>
                            <span className="tb-tnx-date d-md-inline-block d-none">
                              <span className="d-md-none ps-sm-0">Date</span>
                            <span className="d-none d-md-block">
                              <span>Issued By</span>
                              <span className="ps-sm-0">Updated</span></span>
                            </span>
                          </th>
                          <th className="tb-tnx-amount is-alt"><span className="tb-tnx-total d-md-inline-block d-none">Total</span><span className="tb-tnx-status text-end d-none d-md-inline-block">Status</span></th>
                          <th className="tb-tnx-action">
                            <UncontrolledDropdown>
                              <DropdownToggle
                                tag="a"
                                className="text-soft dropdown-toggle btn btn-icon btn-trigger"
                              >
                                <Icon name="more-h"></Icon>
                              </DropdownToggle>
                              <DropdownMenu end>
                                <ul className="link-list-opt text-capitalize" style={{letterSpacing: "normal"}}>
                                  <li>
                                    <DropdownItem tag="a" className="pointer-cursor text-danger" onClick={() => handleSelectedSaleAction('cancel')}>
                                      <Icon name="na"></Icon>
                                      <span>Cancel Select Sales</span>
                                      
                                    </DropdownItem>
                                  </li>
                                  <li>
                                    <DropdownItem tag="a" className="pointer-cursor text-danger" onClick={() => handleSelectedSaleAction('refund')}>
                                      <Icon name="curve-up-left"></Icon>
                                      <span>Refund Select Sales</span>
                                    </DropdownItem>
                                  </li>
                                </ul>                                
                              </DropdownMenu>
                            </UncontrolledDropdown>

                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          tableData.sales.map((data, index) => {
                            let salesStatus = <div className="tb-tnx-status text-end"><Badge className="badge-dot" color="success">Paid</Badge></div>;
                            
                            switch (data.status) {
                              case 'invoice':
                                salesStatus = data.amount_paid === "0.00" ? <div className="tb-tnx-status text-end"><Badge className="badge-dot" color="warning">Unpaid</Badge></div> : <div className="tb-tnx-status text-end"><Badge className="badge-dot" color="warning">Part-Paid</Badge></div>;
                                break;
                              
                              case 'quote':
                                salesStatus = <div className="tb-tnx-status text-end"><Badge className="badge-dot" color="gray">Quote</Badge></div>;
                                break;

                              case 'cancelled':
                                salesStatus = <div className="tb-tnx-status text-end"><Badge className="badge-dot" color="danger">Cancelled</Badge></div>;
                                break;

                              case 'refunded':
                                salesStatus = <div className="tb-tnx-status text-end"><Badge className="badge-dot" color="danger">Refunded</Badge></div>;
                                break;
                            }
                            let amount = <div className="tb-tnx-total">
                                            <span className="amount">
                                              <small className="text-muted">{currency} </small>
                                              {data.amount_paid}
                                              <small className="text-muted fw-bold"> / {data.total_amount}</small>
                                            </span>
                                          </div>;
                            
                            if(data.amount_paid === data.total_amount){
                              amount = <div className="tb-tnx-total">
                                          <span className="amount">
                                            <small className="text-muted">{currency} </small>
                                            {data.amount_paid}
                                          </span>
                                        </div>
                            }

                            return (
                              <tr className="tb-tnx-item sales-row" key={index}>
                                <td className="tb-tnx-checkbox nk-tb-col-check">
                                  <div className="custom-control custom-control-sm custom-checkbox">
                                    <input
                                      type="checkbox"
                                      checked={checkAll || checkedBoxes.includes(data.sale_id)}
                                      onChange={() => handleCheckboxChange(data.sale_id)}
                                      className="custom-control-input table-checkbox"
                                      id={`item${data.sale_id}`} 
                                    />
                                    <label className="custom-control-label" htmlFor={`item${data.sale_id}`} />
                                  </div>
                                </td>
                                <td className="tb-tnx-id pointer-cursor"><span className="text-primary d-none d-md-inline-block" onClick={() => {history.push(`${process.env.PUBLIC_URL}/sales/b/${businessID}/details/${data.sale_id}`)}}>#{data.sale_number}</span></td>
                                <td className="tb-tnx-info pointer-cursor" onClick={() => {history.push(`${process.env.PUBLIC_URL}/sales/b/${businessID}/details/${data.sale_id}`)}}>
                                  <div className="tb-tnx-desc">
                                    <p className="mb-0 mt-1 d-block d-sm-none text-bold"><span className="text-primary" onClick={() => {history.push(`${process.env.PUBLIC_URL}/sales/b/${businessID}/details/${data.sale_id}`)}}>#{data.sale_number}</span></p>
                                    <span className="title">{data.customer_name}
                                    {
                                      data.viewed > 0 && <em className="text-muted icon ni ni-eye ms-1" title="Viewed"></em>
                                    }
                                    {
                                      data.change != "0.00" && <em className="text-muted icon ni ni-coins ms-1" title="Has change"></em>
                                    }
                                    {
                                      data.feedback && <em className="text-muted icon ni ni-chat ms-1" title="customer feedback"></em>
                                    }
                                    {
                                      data.order_log && <em className="text-muted icon ni ni-cart ms-1" title="added to orders"></em>
                                    }
                                  </span></div>
                                  <div className="tb-tnx-date">
                                    <span className="date d-none d-md-inline-block">{data.issued_by}</span>
                                    <span className="date ps-0">{moment(data.updated_at).format("Do MMM, YYYY")}</span>
                                  </div>
                                </td>
                                <td className="tb-tnx-amount is-alt">
                                  {amount}
                                  {salesStatus}
                                </td>
                                <td className="tb-tnx-action">
                                  <UncontrolledDropdown>
                                    <DropdownToggle
                                      tag="a"
                                      className="text-soft dropdown-toggle btn btn-icon btn-trigger"
                                    >
                                      <Icon name="more-h"></Icon>
                                    </DropdownToggle>
                                    <DropdownMenu end>
                                      <ul className="link-list-opt no-bdr">
                                        <li>
                                          <Link to={`${process.env.PUBLIC_URL}/sales/b/${businessID}/details/${data.sale_id}`}>                                            
                                            <Icon name="eye"></Icon>
                                            <span>View Sale</span>
                                          </Link>
                                        </li>
                                        {
                                          data.amount_paid === '0.00' && !['receipt','cancelled','refunded'].includes(data.status) &&
                                          <li>
                                            <Link to={`${process.env.PUBLIC_URL}/sales/b/${businessID}/edit/${data.sale_id}`}>
                                              <Icon name="edit-alt"></Icon>
                                              <span>Edit Sale</span>
                                            </Link>
                                          </li>
                                        }

                                        <li>
                                          <Link to={`${process.env.PUBLIC_URL}/create-invoice-receipt/b/${businessID}?s=${data.sale_id}`}>
                                            <Icon name="copy-fill"></Icon>
                                            <span>Create a Copy</span>
                                          </Link>
                                        </li>

                                        {
                                          !['receipt','cancelled','refunded'].includes(data.status) &&
                                          <>
                                            <li className="divider"></li>
                                            <li>
                                              <DropdownItem tag="a" className="pointer-cursor text-danger" onClick={() => handleSingleSelectSaleAction(data.sale_id, 'cancel')}>
                                                <Icon name="na"></Icon>
                                                <span>Cancel</span>
                                              </DropdownItem>
                                            </li>
                                            {
                                              data.status !== 'quote' &&
                                              <li>
                                                <DropdownItem tag="a" className="pointer-cursor text-danger" onClick={() => handleSingleSelectSaleAction(data.sale_id, 'refund')}>
                                                  <Icon name="curve-up-left"></Icon>
                                                  <span>Refund</span>
                                                </DropdownItem>
                                              </li>
                                            }
                                          </>                                          
                                        }
                                      </ul>
                                    </DropdownMenu>
                                  </UncontrolledDropdown>
                                </td>
                              </tr>
                            )
                          })
                        }                                         
                      </tbody>
                    </table>
                    {
                      showPagination &&
                      <>
                        <hr className="m-3" />
                        <PaginationWithOnclick currentPage={filters.page} pageCount={totalPages} loadNextPage={loadNextPage} />
                      </>
                    }
                    
                  </>
                }              
              </div>
            </div>
          </Card>

          {
            activeModal === "cancelAlertModal" &&
            <AlertModal showModal={true} toggleModal={() => toggleModal('cancelAlertModal')}
              headerText={checkedBoxes.length > 1 ? `Cancel Sales` : `Cancel Sale`} 
              descriptionText={`${checkAll ? 
              `Are you sure you want to cancel all ${tableData.meta.total_records} sales. This action can not be reverted.` 
              :
              `Are you sure you want to cancel ${checkedBoxes.length > 1 ? `these ${checkedBoxes.length} selected sales` : `this selected sale`}. This action can not be reverted.`}`}
              leftButtonText={`Cancel`}
              leftButtonOnClick={checkAll ? cancelSalesByFilter : cancelSalesByIDs} >
              <div className="form-group mt-5">
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    checked={alertCustomer}
                    onChange={() => setAlertCustomer(!alertCustomer)}
                    id="alert_customer" />
                  <label className="custom-control-label" htmlFor="alert_customer">
                    {`Alert ${checkedBoxes.length > 1 ? `customers` : `customer`} of cancel status`}
                  </label>
                </div>
              </div>
            </AlertModal>
          }
          
          {
            activeModal === "refundAlertModal" &&
            <AlertModal showModal={true} toggleModal={() => toggleModal('refundAlertModal')}
              headerText={checkedBoxes.length > 1 ? `Refund Sales` : `Refund Sale`} 
              descriptionText={`${checkAll ? 
              `Are you sure you want to refund all ${tableData.meta.total_records} sales. This action can not be reverted.` 
              :
              `Are you sure you want to refund ${checkedBoxes.length > 1 ? `these ${checkedBoxes.length} selected sales` : `this selected sale`}. This action can not be reverted.`}`}
              leftButtonText={`Refund`}
              leftButtonOnClick={checkAll ? refundSalesByFilter : refundSalesByIDs} >
              <div className="form-group mt-5">
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    checked={alertCustomer}
                    onChange={() => setAlertCustomer(!alertCustomer)}
                    id="alert_customer" />
                  <label className="custom-control-label" htmlFor="alert_customer">
                    {`Alert ${checkedBoxes.length > 1 ? `customers` : `customer`} of refund status`}
                  </label>
                </div>
              </div>
            </AlertModal>
          }

          {
            activeModal === "noSelectionAlertModal" &&
            <AlertModal showModal={true} toggleModal={() => toggleModal('noSelectionAlertModal')}
              headerText={`Error`} 
              descriptionText={`No sale has been selected. Select a sale and try again.`}
            />
          }

          { (activeModal === "cancelLoadingModal" || activeModal === "refundLoadingModal") && <LoadingModal showModal={true} headerText={`${activeModal === 'cancelLoadingModal' ? `Cancelling...` : `Refunding...`}`} descriptionText={`Please wait while your ${checkedBoxes.length > 1 ? `sales are` : `sale is`} being ${activeModal === 'cancelLoadingModal' ? `cancelled` : `refunded`}`} /> }
    
          { 
            (activeModal === "successCancelModal" || activeModal === "successRefundModal") &&
            <SuccessModal showModal={true} toggleModal={() => toggleModal(null)}
              headerText={`${activeModal === 'successCancelModal' ? `Cancelled` : `Refunded`}`} descriptionText={`Sale(s) ${activeModal === 'successCancelModal' ? `cancelled` : `refunded`} successfully.`} 
            />
          }
          
          {
            (activeModal === "cancelErrorModal" || activeModal === "refundErrorModal") &&
            <AlertModal showModal={true} toggleModal={() => toggleModal(null)}
              headerText={"Error"} descriptionText={errorMessage}                       
            />
          }
        </>
      }
    </Block>
  );
};
