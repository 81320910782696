import React, { useState } from "react";
import { deleteCookie } from "../../../../utils/Utils";
import UserAvatar from "../../../../components/user/UserAvatar";
import { DropdownToggle, DropdownMenu, Dropdown } from "reactstrap";
import { Icon } from "../../../../components/Component";
import { LinkList, LinkItem } from "../../../../components/links/Links";
import { deleteOfflineDB } from "../../../../utils/OfflineDB";

const User = ({themeState, setThemeState, ...props}) => {
  const [open, setOpen] = useState(false);
  const [darkMode, setDarkMode] = useState(localStorage.getItem("theme") === null || localStorage.getItem("theme") === "light" ? false : true);
  const toggle = () => setOpen((prevState) => !prevState);
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  
  const handleSignOut = async () => {
    deleteCookie("access_token");
    localStorage.clear();
    await deleteOfflineDB();
    window.location = `${process.env.PUBLIC_URL}/login`;
  };

  const handleSwitchChange = () => {
    let skin = darkMode ? "light" : "dark";
    setDarkMode(!darkMode);
    let updatedThemeState = themeState;
    updatedThemeState.skin = skin;
    localStorage.setItem("theme", skin);
    setThemeState({...updatedThemeState});
  };

  return (
    <Dropdown isOpen={open} className="user-dropdown" toggle={toggle}>
      <DropdownToggle
        tag="a"
        href="#toggle"
        className="dropdown-toggle"
        onClick={(ev) => {
          ev.preventDefault();
        }}
      >
        <div className="user-toggle">
          <UserAvatar icon="user-alt" className="sm" />
          <div className="user-name dropdown-indicator d-none d-sm-block">{userInfo.full_name}</div>
        </div>
      </DropdownToggle>
      <DropdownMenu end className="dropdown-menu-md dropdown-menu-s1">
        <div className="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
          <div className="user-card sm">
            <UserAvatar icon="user-alt" className="sm" />
            <div className="user-info">
              <span className="lead-text">{userInfo.full_name}</span>
              <span className="sub-text">{userInfo.email}</span>
            </div>
          </div>
        </div>
        <div className="dropdown-inner">
          <LinkList>
            {/* <LinkItem link="/user-profile-regular" icon="user-alt" onClick={toggle}>
              View Profile
            </LinkItem> */}
            <LinkItem link="/account-settings" icon="setting-alt" onClick={toggle}>
              Account Setting
            </LinkItem>
            {/* <LinkItem link="/user-profile-activity" icon="activity-alt" onClick={toggle}>
              Login Activity
            </LinkItem> */}
          </LinkList>
        </div>
        
        <div className="dropdown-inner">
          <LinkList>
            <a href="#" onClick={handleSignOut}>
              <Icon name="signout"></Icon>
              <span>Sign Out</span>
            </a>
          </LinkList>
        </div>
        <div className="dropdown-inner">
          <LinkList>
            <div className="custom-control custom-switch d-block">
              <input
                type="checkbox"
                className="custom-control-input"
                defaultChecked={darkMode}
                onClick={() => { handleSwitchChange() }}
                id="dark-mode"
              />
              <label className="custom-control-label fs-13px fw-medium d-block" htmlFor="dark-mode"> Dark Mode </label>
            </div>
          </LinkList>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

export default User;
