import React, { useState, useEffect } from "react";
import { Empty, Select } from 'antd';
import { baseURLs } from '../../../utils/Constants';
import axios from 'axios';
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import moment from 'moment';
import { formatTo2DP, getAxiosHeaders, getQueryParams, moneyFormat } from "../../../utils/Utils";
import { Badge, 
  DropdownToggle, 
  DropdownMenu, 
  Card, 
  UncontrolledDropdown, 
  DropdownItem, 
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter } from "reactstrap";
import {
  Block,
  Icon,
  Button
} from "../../../components/Component";
import {
  AlertModal,
  SuccessModal,
  LoadingModal
} from "../AlertModals";
import { TableLoader } from "../../../utils/Loaders";
import { PaginationWithOnclick } from "./Pagination";
import { deleteOfflineSalesByIds, getOfflineSaleBalance, getTaxProfiles_OffDB, offlineDB } from "../../../utils/OfflineDB";
import { Numeral } from "react-numeral";

export const OfflineSalesTable = ({ history, businessID, currency, showFilter = true, showPagination = true, showStats = true, salesData = null, page, title = null, ...props }) => {
  const [tableData, setTableData] = useState(salesData);
  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(1);
  const [hasFilters, setHasFilters] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const [checkedBoxes, setCheckedBoxes] = useState([]);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [filters, setFilters] = useState({
    business: businessID,
    page: 1,
    customer_search: '',
    phone_search: '',
  });
  const [downloadLink, setDownloadLink] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [activeModal, setActiveModal] = useState(null);
 
  const toggleModal = (modal) => {
    if (activeModal === modal) {
      setActiveModal(null);
    } else {
      setActiveModal(modal);
    }
  };

  const onInputChange = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  const onDateChange = (date, type) => {
    let params = filters;

    if(!moment(date).isValid()){
      params.date_range = null;
      params.dateRangeStart = null;
      params.dateRangeEnd = null;
      setFilters({...params});
      return;
    }

    if(type === 'from') {
      params.dateRangeStart = date;  
      setFromDate(date);  
    } else {
      params.dateRangeEnd = date;
      setToDate(date);  
    }

    if(moment(params.dateRangeStart).isValid() && moment(params.dateRangeEnd).isValid()) {
      params.date_range = `${moment(params.dateRangeStart).format("YYYY-MM-DD")},${moment(params.dateRangeEnd).format("YYYY-MM-DD")}`;
      setFilters({...params});      
    }
  }

  const onSelectChange = (value, name) => {
    setFilters({ ...filters, [name]: value });
  };

  const selectAll = () => {
    setCheckAll(prevCheckAll => {
      if (prevCheckAll) {
        setCheckedBoxes([]);
      } else {
        const allIDs = tableData.sales.map(item => item.sale_id);
        setCheckedBoxes(allIDs);
      }
      return !prevCheckAll;
    });
  }

  const handleCheckboxChange = (value) => {
    setCheckedBoxes(prevBoxes => {
      if (prevBoxes.includes(value)) {
          return prevBoxes.filter(id => id !== value);
      } else {
          return [...prevBoxes, value];
      }
    });
    if (checkAll) {
        setCheckAll(false);
    }
  };

  const currentUrl = (filters) => {
    let customer_search = filters.customer_search.length > 0 ? `&cs=${filters.customer_search}` : ``;
    let phone_search = filters.phone_search.length > 0 ? `&ps=${filters.phone_search}` : ``;
    
    if( (customer_search !== '' || phone_search !== '') && !hasFilters) {
      setHasFilters(true)
    }

    let params = `${customer_search}${phone_search}`;
    let url = `${process.env.PUBLIC_URL}${window.location.pathname}?p=${filters.page}${params}`;
    history.replace(url);
  }

  const loadNextPage = (page) => {
    let params = filters;
    params.page = page;
    setFilters({...params});
    getSales(params)
  }

  const resetFilter = () => {
    let params = {
      business: businessID,
      page: 1,
      customer_search: '',
      phone_search: '',
    };
  
    toggleModal('filterModal');
    setHasFilters(false);
    setFromDate(null);
    setToDate(null);
    setFilters({ ...params });
    getSales(params);
  }

  const filterSales = () => {
    toggleModal('filterModal');
    setHasFilters(true);
    let params = filters;
    params.page = 1;
    setFilters({...params});
    getSales(filters);
  }

  const getSaleSummary = (items, fulfillment_cost, discount_amount, discount_type, amount_received, taxes, sale_status) => {
    items = items || [];
    let fulfillmentCost = formatTo2DP(fulfillment_cost || 0);
    let discountAmount = formatTo2DP(discount_amount || 0);
    const discountType = discount_type || '';
    let amountReceived = formatTo2DP(amount_received || 0);

    let calculatedTaxAmounts = [];
    
    // Calculate Subtotal including Fulfillment Cost
    let subTotal = items.reduce((total, item) => {
      const price = item.price || 0;
      const quantity = item.quantity || 0;
      return total + price * quantity;
    }, 0) + formatTo2DP(fulfillmentCost);
    
    // Calculate Direct Taxes on Subtotal including Fulfillment Cost
    let directTaxesTotal = 0;
    taxes.forEach(tax => {
      if (tax.type === 'direct') {
        let taxAmount = formatTo2DP((subTotal * tax.percentage) / 100);
        calculatedTaxAmounts.push({
          tax: tax.tax_name,
          percentage: tax.percentage,
          amount: taxAmount,
          type: 'direct',
        });
        
        directTaxesTotal += taxAmount;
        
      }
    });
  
    // Calculate Subtotal with Direct Taxes and Fulfillment Cost
    let subTotalWithDirectTaxes = formatTo2DP(subTotal + directTaxesTotal);
  
    // Calculate Compound Taxes on Subtotal with Direct Taxes and Fulfillment Cost
    let compoundTaxesTotal = 0;
    taxes.forEach(tax => {
      if (tax.type === 'compound') {
        let taxAmount = formatTo2DP((subTotalWithDirectTaxes * tax.percentage) / 100);
        calculatedTaxAmounts.push({
          tax: tax.tax_name,
          percentage: tax.percentage,
          amount: taxAmount,
          type: 'compound',
        });

        compoundTaxesTotal += taxAmount;
      }
    });
  
    // Calculate Total Tax Amount
    let totalTaxAmount = formatTo2DP(directTaxesTotal + compoundTaxesTotal);
  
    // Calculate Total Amount including Taxes, Fulfillment Cost, and Discounts
    let totalAmount = formatTo2DP(subTotalWithDirectTaxes + compoundTaxesTotal);
    
    let discountPercentage = 0;
    if(totalAmount !== 0){
      if (discountType === 'fixed') {
        discountPercentage = formatTo2DP((discountAmount * 100) / totalAmount);
        totalAmount -= formatTo2DP(discountAmount); // Deduct fixed discount amount
      } else if (discountType === 'percentage') {
        discountPercentage = discountAmount;
        discountAmount = formatTo2DP((totalAmount * discountAmount) / 100);
        totalAmount -= discountAmount; // Deduct discount percentage
      }
    }

    // Calculate balance due
    let balanceDue = formatTo2DP(totalAmount - amountReceived);
    let amountPaid = totalAmount >= amountReceived ? amountReceived : totalAmount;
    let saleStatus = sale_status;

    if(totalAmount <= amountPaid){
      saleStatus = 'receipt';
    } else if(amountPaid > 0) {
      saleStatus = 'invoice';
    }

    // Update sale summary state
    return {
      sub_total: subTotal,
      total_direct_tax: directTaxesTotal,
      total_compound_tax: compoundTaxesTotal,
      total_tax: totalTaxAmount,
      taxes: calculatedTaxAmounts,
      discount_amount: discountAmount,
      discount_percentage: discountPercentage,
      total_amount: totalAmount,
      amount_received: amountReceived,
      amount_paid: amountPaid,
      balance_due: balanceDue < 0 ? 0 : balanceDue,
      change: balanceDue < 0 ? balanceDue * -1 : 0,
      status: saleStatus
    };
  }

  const getSales = async(filters) => {
    setLoading(true);

    try {    
      const { page, customer_search, phone_number } = filters;

      // Calculate offset for pagination
      const offset = (page - 1) * 10;

      // Start with the offline_sales table
      let query = await offlineDB.offline_sales
      .where('business_id')
      .equals(businessID)
      .toArray();

      query = query.sort((a, b) => b.id - a.id);

      // Apply filters conditionally
      if (customer_search) {
        query = query.filter(sale => sale.customer_name.toLowerCase().includes(customer_search.toLowerCase()));
      }
      if (phone_number) {
        query = query.filter(sale => sale.phone_number.includes(phone_number));
      }


      // Get total filtered count for pagination info
      const totalCount = query.length;

      if(totalCount > 0) {
        // Apply pagination (offset and limit)
        let sales = query.slice(offset, offset + 10);
  
        let _sales = [];
        for(let i = 0; i < sales.length; i++) {

          let totalAmountPaid = parseFloat(sales[i].amount_received);
          let saleBalancePayment = await getOfflineSaleBalance(sales[i].id);

          if(saleBalancePayment.length > 0) {
            const totalBalancePaid = saleBalancePayment.reduce((total, payment) => total + parseFloat(payment.amount_paid), 0);
            totalAmountPaid = totalAmountPaid + totalBalancePaid;
          }

          let saleItems = JSON.parse(sales[i].items) || [];

          let taxes = [];
          if(sales[i].tax_profile){
            let taxProfiles = await getTaxProfiles_OffDB(businessID);
            if(taxProfiles.length > 0) {
              taxes = taxProfiles.filter((profile) => profile.tax_id == sales[i].tax_profile)
            }
          }

          let saleSummary = getSaleSummary(
            saleItems,
            sales[i].fulfillment_cost,
            sales[i].discount_amount,
            sales[i].discount_type,
            totalAmountPaid,
            taxes,
            sales[i].sale_type
          );

          _sales.push({
            sale_id: sales[i].id,
            sale_number: `OFF-${sales[i].id}`,
            customer_name: sales[i].customer_name.length > 0 ? sales[i].customer_name : `Customer`,
            status: saleSummary.status,
            issued_by: 'You',
            amount_paid: moneyFormat(saleSummary.amount_paid),
            total_amount: moneyFormat(saleSummary.total_amount),
            updated_at: moment(sales[i].issued_date).format("YYYY-MM-DD HH:mm:ss"),
          })
        }
  
        setTotalPages(Math.ceil(totalCount / 10));
        setTableData({
          meta: {
            total_records: totalCount
          },
          sales: [..._sales]
        });

      } else {
        setTotalPages(0);
        setTableData({
          meta: {
            total_records: 0
          },
          sales: []
        });
      }

      currentUrl(filters);
      setLoading(false);

    } catch (error) {
      setTotalPages(0);
      setTableData({
        meta: {
          total_records: 0
        },
        sales: []
      });

      setLoading(false);
      setErrorMessage(`Error fetching paginated offline sales: ${error}`);
    }

  }

  const deleteSalesByIDs = async() => {
    toggleModal('deleteLoadingModal');

    await deleteOfflineSalesByIds(checkedBoxes);
    getSales(filters);
    toggleModal('successDeleteModal');
  }

  const deleteSalesByFilter = async() => {
    toggleModal('deleteLoadingModal');

    const { customer_search, phone_number } = filters;

    let query = await offlineDB.offline_sales
      .where('business_id')
      .equals(businessID)
      .toArray();

    // Apply filters conditionally
    if (customer_search) {
      query = query.filter(sale => sale.customer_name.toLowerCase().includes(customer_search.toLowerCase()));
    }
    if (phone_number) {
      query = query.filter(sale => sale.phone_number.includes(phone_number));
    }

    if(query.length > 0) {
      const saleIDs = query.map(sale => sale.id);

      await deleteOfflineSalesByIds(saleIDs);
      getSales(filters);
      toggleModal('successCancelModal');
      setCheckAll(false);
    } else {
      setErrorMessage("No offline sale found");
      toggleModal('deleteErrorModal');
    }

  }

  const handleSelectedSaleAction = (action) => {
    if(!checkAll && checkedBoxes.length === 0){
      toggleModal('noSelectionAlertModal');
      return;
    }

    toggleModal('deleteAlertModal');
  }

  const handleSingleSelectSaleAction = (saleID, action) => {
    setCheckedBoxes([saleID]);

    setTimeout(() => {
      toggleModal('deleteAlertModal');
    }, 500);
  }

  useEffect(() => {
    setLoading(true);
    let url_string = window.location.href;
    let url = new URL(url_string);
    let queryParams = getQueryParams(url);

    let page = queryParams.hasOwnProperty('p') ? queryParams.p : 1;
    let customer_search = queryParams.hasOwnProperty('cs') ? queryParams.cs : '';
    let phone_search = queryParams.hasOwnProperty('ps') ? queryParams.ps : '';    

    let params = filters;
    params.page = page;
    params.customer_search = customer_search;
    params.phone_search = phone_search;

    setFilters(params)
    getSales(params);

  }, []);

  
  return (
    <Block>

      {
        loading ?
        <div className="card-inner p-0">
          <TableLoader />
        </div>
        :
        <>
          <Card className="card-bordered card-stretch">
            <div className="card-inner-group">
              <div className="card-inner">
                <div className="card-title-group">
                  <div className="card-title">
                    <h6 className="title">
                    {tableData.meta.total_records} {tableData.meta.total_records > 1 ? `Records` : `Record`}
                    </h6>
                  </div>
                  {
                    showFilter &&
                    <div className="card-tools me-n1">
                      <ul className="btn-toolbar">
                        <li>
                          <Button className="btn-icon btn-trigger" onClick={() => toggleModal('filterModal')} title="Filter">
                            { hasFilters && <div className="dot dot-primary"></div> }
                            <Icon name="search"></Icon>
                          </Button>
                          {
                            activeModal === "filterModal" &&
                            <Modal isOpen={true} toggle={() => toggleModal('filterModal')}>
                              <ModalHeader
                                toggle={() => toggleModal('filterModal')}
                                close={
                                  <button className="close" onClick={() => toggleModal('filterModal')}>
                                    <Icon name="cross" />
                                  </button>
                                }
                              >
                                Sales Filter
                              </ModalHeader>
                              <ModalBody>
                                <form>
                                  <div className="form-group">
                                    <label className="form-label" htmlFor="customer_search">
                                      Customer Name / Customer ID
                                    </label>
                                    <div className="form-control-wrap">
                                      <input type="text" onChange={onInputChange} name="customer_search" className="form-control form-control-lg" id="customer_search" defaultValue={filters.customer_search} />
                                    </div>
                                  </div>
                                  <div className="form-group">
                                    <label className="form-label" htmlFor="phone_search">
                                      Phone Number
                                    </label>
                                    <div className="form-control-wrap">
                                      <input type="text" onChange={onInputChange} name="phone_search" className="form-control form-control-lg" id="phone_search" defaultValue={filters.phone_search} />
                                    </div>
                                  </div>
                                </form>
                              </ModalBody>
                              <ModalFooter className="bg-lighter justify-content-center">
                                <Button color="primary" type="submit" onClick={(ev) => { ev.preventDefault(); filterSales();} } size="lg">
                                  Apply Filter
                                </Button>

                                {
                                  hasFilters &&
                                  <Button className="ms-3 text-muted" color="light" onClick={(ev) => { ev.preventDefault(); resetFilter();} } size="lg">
                                    Reset Filter
                                  </Button>
                                }
                              </ModalFooter>
                            </Modal>
                          }
                        </li>
                        <li className="btn-toolbar-sep"></li>
                        <li>
                          <Button className="btn-icon btn-trigger" title="Reload" onClick={() => {getSales(filters)}}>
                            <Icon name="redo"></Icon>
                          </Button>
                        </li>
                      </ul>
                    </div>
                  }
                </div>
              </div>          
              <div className="card-inner p-0">
                {
                  tableData.sales.length === 0 ?
                  <div className="text-center m-5">
                    <div className="price-plan-media"><Empty image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg" imageStyle={{height: 60}} description={false} /></div>
                    <div className="price-plan-info">
                      <h5 className="title fw-normal">No offline sales found</h5>
                      <span>Record a sale by sending an invoice, a quote, or a receipt to your customers.</span>
                    </div>
                    <div className="price-plan-action">
                      <Link to={`${process.env.PUBLIC_URL}/create-invoice-receipt/b/${businessID}`}>
                        <button className="btn btn-outline-primary">Create Invoice / Receipt</button>
                      </Link>                    
                    </div>
                  </div>
                  :
                  <>                   
                    <table className="table table-tranx">
                      <thead>
                        <tr className="tb-tnx-head">
                          <th className="tb-tnx-checkbox nk-tb-col-check">
                            <div className="custom-control custom-control-sm custom-checkbox">
                              <input
                                type="checkbox"
                                checked={checkAll}
                                onChange={selectAll}
                                className="custom-control-input table-checkbox"
                                id="select_all" />
                              <label className="custom-control-label" htmlFor="select_all"/>
                            </div>
                          </th>
                          <th className="tb-tnx-id"><span className="d-none d-sm-inline-block">#</span></th>
                          <th className="tb-tnx-info">
                            <span className="tb-tnx-desc d-none d-sm-inline-block">
                              <span>Customer</span>
                            </span>
                            <span className="tb-tnx-date d-md-inline-block d-none">
                              <span className="d-md-none ps-sm-0">Date</span>
                            <span className="d-none d-md-block">
                              <span>Issued By</span>
                              <span className="ps-sm-0">Updated</span></span>
                            </span>
                          </th>
                          <th className="tb-tnx-amount is-alt"><span className="tb-tnx-total d-md-inline-block d-none">Total</span><span className="tb-tnx-status text-end d-none d-md-inline-block">Status</span></th>
                          <th className="tb-tnx-action">
                            <UncontrolledDropdown>
                              <DropdownToggle
                                tag="a"
                                className="text-soft dropdown-toggle btn btn-icon btn-trigger"
                              >
                                <Icon name="more-h"></Icon>
                              </DropdownToggle>
                              <DropdownMenu end>
                                <ul className="link-list-opt text-capitalize" style={{letterSpacing: "normal"}}>
                                  <li>
                                    <DropdownItem tag="a" className="pointer-cursor text-danger" onClick={() => handleSelectedSaleAction('cancel')}>
                                      <Icon name="trash"></Icon>
                                      <span>Delete Select Sales</span>
                                      
                                    </DropdownItem>
                                  </li>
                                </ul>                                
                              </DropdownMenu>
                            </UncontrolledDropdown>

                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          tableData.sales.map((data, index) => {
                            let salesStatus = <div className="tb-tnx-status text-end"><Badge className="badge-dot" color="success">Paid</Badge></div>;
                            
                            switch (data.status) {
                              case 'invoice':
                                salesStatus = data.amount_paid === "0.00" ? <div className="tb-tnx-status text-end"><Badge className="badge-dot" color="warning">Unpaid</Badge></div> : <div className="tb-tnx-status text-end"><Badge className="badge-dot" color="warning">Part-Paid</Badge></div>;
                                break;
                              
                              case 'quote':
                                salesStatus = <div className="tb-tnx-status text-end"><Badge className="badge-dot" color="gray">Quote</Badge></div>;
                                break;

                              case 'cancelled':
                                salesStatus = <div className="tb-tnx-status text-end"><Badge className="badge-dot" color="danger">Cancelled</Badge></div>;
                                break;

                              case 'refunded':
                                salesStatus = <div className="tb-tnx-status text-end"><Badge className="badge-dot" color="danger">Refunded</Badge></div>;
                                break;
                            }
                            let amount = <div className="tb-tnx-total">
                                            <span className="amount">
                                              <small className="text-muted">{currency} </small>
                                              <Numeral value={data.amount_paid.toString()} format={"0,0.00"} />
                                              <small className="text-muted fw-bold"> / 
                                                <Numeral value={data.total_amount.toString()} format={"0,0.00"} />
                                              </small>
                                            </span>
                                          </div>;
                            
                            if(data.amount_paid === data.total_amount){
                              amount = <div className="tb-tnx-total">
                                          <span className="amount">
                                            <small className="text-muted">{currency} </small>
                                            <Numeral value={data.amount_paid.toString()} format={"0,0.00"} />
                                          </span>
                                        </div>
                            }

                            return (
                              <tr className="tb-tnx-item sales-row" key={index}>
                                <td className="tb-tnx-checkbox nk-tb-col-check">
                                  <div className="custom-control custom-control-sm custom-checkbox">
                                    <input
                                      type="checkbox"
                                      checked={checkAll || checkedBoxes.includes(data.sale_id)}
                                      onChange={() => handleCheckboxChange(data.sale_id)}
                                      className="custom-control-input table-checkbox"
                                      id={`item${data.sale_id}`} 
                                    />
                                    <label className="custom-control-label" htmlFor={`item${data.sale_id}`} />
                                  </div>
                                </td>
                                <td className="tb-tnx-id pointer-cursor"><span className="text-primary d-none d-md-inline-block" onClick={() => {history.push(`${process.env.PUBLIC_URL}/sales/b/${businessID}/offline-details/${data.sale_id}`)}}>#{data.sale_number}</span></td>
                                <td className="tb-tnx-info pointer-cursor" onClick={() => {history.push(`${process.env.PUBLIC_URL}/sales/b/${businessID}/offline-details/${data.sale_id}`)}}>
                                  <div className="tb-tnx-desc">
                                    <p className="mb-0 mt-1 d-block d-sm-none text-bold"><span className="text-primary" onClick={() => {history.push(`${process.env.PUBLIC_URL}/sales/b/${businessID}/offline-details/${data.sale_id}`)}}>#{data.sale_number}</span></p>
                                    <span className="title">{data.customer_name}</span>
                                  </div>
                                  <div className="tb-tnx-date">
                                    <span className="date d-none d-md-inline-block">{data.issued_by}</span>
                                    <span className="date ps-0">{moment(data.updated_at).format("Do MMM, YYYY")}</span>
                                  </div>
                                </td>
                                <td className="tb-tnx-amount is-alt">
                                  {amount}
                                  {salesStatus}
                                </td>
                                <td className="tb-tnx-action">
                                  <UncontrolledDropdown>
                                    <DropdownToggle
                                      tag="a"
                                      className="text-soft dropdown-toggle btn btn-icon btn-trigger"
                                    >
                                      <Icon name="more-h"></Icon>
                                    </DropdownToggle>
                                    <DropdownMenu end>
                                      <ul className="link-list-opt no-bdr">
                                        <li>
                                          <Link to={`${process.env.PUBLIC_URL}/sales/b/${businessID}/offline-details/${data.sale_id}`}>                                            
                                            <Icon name="eye"></Icon>
                                            <span>View Sale</span>
                                          </Link>
                                        </li>
                                        <li className="divider"></li>
                                        <li>
                                          <DropdownItem tag="a" className="pointer-cursor text-danger" onClick={() => handleSingleSelectSaleAction(data.sale_id, 'delete')}>
                                            <Icon name="trash"></Icon>
                                            <span>Delete</span>
                                          </DropdownItem>
                                        </li>
                                        
                                      </ul>
                                    </DropdownMenu>
                                  </UncontrolledDropdown>
                                </td>
                              </tr>
                            )
                          })
                        }                                         
                      </tbody>
                    </table>
                    {
                      showPagination &&
                      <>
                        <hr className="m-3" />
                        <PaginationWithOnclick currentPage={filters.page} pageCount={totalPages} loadNextPage={loadNextPage} />
                      </>
                    }
                    
                  </>
                }              
              </div>
            </div>
          </Card>

          {
            activeModal === "deleteAlertModal" &&
            <AlertModal showModal={true} toggleModal={() => toggleModal('deleteAlertModal')}
              headerText={checkedBoxes.length > 1 ? `Delete Offline Sales` : `Delete Offline Sale`} 
              descriptionText={`${checkAll ? 
              `Are you sure you want to delete all ${tableData.meta.total_records} offline sales. This action can not be reverted.` 
              :
              `Are you sure you want to delete ${checkedBoxes.length > 1 ? `these ${checkedBoxes.length} selected offline sales` : `this selected offline sale`}. This action can not be reverted.`}`}
              leftButtonText={`Delete`}
              leftButtonOnClick={checkAll ? deleteSalesByFilter : deleteSalesByIDs} />
          }

          {
            activeModal === "noSelectionAlertModal" &&
            <AlertModal showModal={true} toggleModal={() => toggleModal('noSelectionAlertModal')}
              headerText={`Error`} 
              descriptionText={`No sale has been selected. Select a sale and try again.`}
            />
          }

          { activeModal === "deleteLoadingModal" && <LoadingModal showModal={true} headerText={`Deleting`} descriptionText={`Please wait while your ${checkedBoxes.length > 1 ? `offline sales are` : `offline sale is`} being deleted`} /> }
    
          { 
            activeModal === "successDeleteModal" &&
            <SuccessModal showModal={true} toggleModal={() => toggleModal(null)}
              headerText={`Deleted`} descriptionText={`The offline sale(s) has been deleted successfully.`} 
            />
          }
          
          {
            activeModal === "deleteErrorModal" &&
            <AlertModal showModal={true} toggleModal={() => toggleModal(null)}
              headerText={"Error"} descriptionText={errorMessage}                       
            />
          }
        </>
      }
    </Block>
  );
};
