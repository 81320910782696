import React, { useEffect, useState, useLayoutEffect } from "react";
import { useHistory } from 'react-router-dom';
import Pages from "../route/Index";
import Sidebar from "./sidebar/Sidebar";
import Head from "./head/Head";
import Header from "./header/Header";
import Footer from "./footer/Footer";
import classNames from "classnames";
import NotificationManager from '../NotificationManager';
import SubscriptionAlert from "./header/SubscriptionAlert";
import { deleteOfflineSale, getOfflineSaleBalance, offlineDB } from "../utils/OfflineDB";
import { baseURLs } from "../utils/Constants";
import axios from "axios";
import { getAxiosHeaders } from "../utils/Utils";
import { Button, notification, Space } from 'antd';
import { Icon } from "../components/Component";

const Layout = () => {
  let history = useHistory();
  const [mobileView, setMobileView] = useState();
  const [visibility, setVisibility] = useState(false);
  const [showSideNav, setShowSideNav] = useState(false);
  const [themeState, setThemeState] = useState({
    main: "default",
    header: "white",
    skin: localStorage.getItem("theme") === null ? "light" : localStorage.getItem("theme"),
  });

  // State to manage internet connection status
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [api, contextHolder] = notification.useNotification({ stack: { threshold: 1 } });

  const updateOnlineStatus = () => {
    setIsOnline(navigator.onLine);
  };

  const synchronizeOfflineSales = async(saleInfo) => {
    try {
      const response = await axios.post(baseURLs.API_URL + `/sales/offline`, {
        sale: JSON.stringify(saleInfo.sale),
        balance_payment: JSON.stringify(saleInfo.balance_payment)
      }, getAxiosHeaders());

      let responseInfo = response.data;
      let saleID = responseInfo.data.sale_id;
      
      // Delete sale from offline storage after successful upload
      await deleteOfflineSale(saleInfo.sale.id);
      console.log("Offline sale synchronized and deleted:", saleInfo.sale.id);
      
      return {
        sale_info: saleInfo.sale, 
        title: `Offline Sale #OFF-${saleInfo.sale.id} Uploaded`, 
        message: `Offline Sale #OFF-${saleInfo.sale.id} has been uploaded online successfully`,
        sale_id: saleID
      }

    } catch (error) {
      console.error("Error synchronizing offline sales:", error);
      let errorResponse = error?.response?.data || '';
      
      let errorMessage = 'Error: Could not connect to server';
      let otherErrors = '';
      if(errorResponse.hasOwnProperty("error")){
        errorMessage = errorResponse.error;
      }

      if(errorResponse.errors.hasOwnProperty("items")){
        otherErrors = errorResponse.errors.items;
      }

      return {
        sale_info: saleInfo.sale, 
        title: `Error Uploading #OFF-${saleInfo.sale.id}`, 
        message: `An error occurred when uploading offline Sale #OFF-${saleInfo.sale.id}: ${errorMessage}, ${otherErrors}`,
        sale_id: null
      }
    }
  }

  const alertOfflineUpdate = (saleInfo, title, message, saleID = null) => {
    const key = saleInfo.id;
    const btn = (
      <Space>
        {
          saleID ?
          <Button type="link" size="small" onClick={(e) => {
            api.destroy(key);
            history.push(`${process.env.PUBLIC_URL}/sales/b/${saleInfo.business_id}/details/${saleID}`);
          }}>
            View Sale
          </Button>
          :
          <Button type="link" size="small" onClick={(e) => {
            api.destroy(key);
            history.push(`${process.env.PUBLIC_URL}/sales/b/${saleInfo.business_id}/offline-details/${saleInfo.id}`);
          }}>
            View Sale
          </Button>
        }
        <Button type="link" size="small" onClick={(e) => {
            api.destroy();              
          }}>
          Close All
        </Button>
      </Space>
    );

    api.open({
      message: title,
      description: message,
      icon: <Icon name="coins" className={`${saleID ? `text-success` : `text-danger`}`} />,
      btn,
      key,
      duration: null,
    });
  }

  useEffect(() => {
    const uploadOfflineSales = async () => {
      try {
        const sales = await offlineDB.offline_sales.toArray();

        for (const sale of sales) {
          
          let saleInfo = {
            sale,
            balance_payment: []
          };

          // get sale balance payment
          let saleBalancePayment = await getOfflineSaleBalance(sale.id);
          saleInfo.balance_payment = saleBalancePayment;

          console.log("Uploading sale to server:", saleInfo);

          // Replace this with actual API call logic to upload sales
          let result = await synchronizeOfflineSales(saleInfo); 
          alertOfflineUpdate(
            result.sale_info,
            result.title,
            result.message,
            result.sale_id
          )         
        }

        console.log("Offline sales synchronized with the server.");
      } catch (error) {
        console.error("Error uploading offline sales:", error);
      }
    };

    // Set up interval to check for offline sales every 10 minutes (600000 ms)
    const intervalId = setInterval(async () => {
      const offlineSalesCount = await offlineDB.offline_sales.count();
      if (offlineSalesCount > 0 && navigator.onLine) {
        await uploadOfflineSales();
      }
    }, 60000 * 1);

    // Add event listeners for online/offline
    window.addEventListener('online', updateOnlineStatus);
    window.addEventListener('offline', updateOnlineStatus);

    // Cleanup listeners on unmount
    return () => {
      clearInterval(intervalId);
      window.removeEventListener('online', updateOnlineStatus);
      window.removeEventListener('offline', updateOnlineStatus);
    };
  }, []);

  useEffect(() => {
    viewChange();
  }, []);

  // Stops scrolling on overlay
  useLayoutEffect(() => {
    if (visibility) {
      document.body.style.overflow = "hidden";
      document.body.style.height = "100%";
    }
    if (!visibility) {
      document.body.style.overflow = "auto";
      document.body.style.height = "auto";
    }
  }, [visibility]);

  useEffect(() => {
    document.body.className = `nk-body bg-white npc-default has-aside no-touch nk-nio-theme ${
      themeState.skin === "dark" ? "dark-mode" : " "
    }`;

    let noSideNavLinks = ['/my-businesses', '/create-business', '/invitations','/pos/b/','print-sale/b/']
    let matched = noSideNavLinks.find((link) => window.location.pathname.includes(link));
    if ( matched ) {
      document.body.classList.add("apps-only");
      setShowSideNav(false);
    } else {
      document.body.classList.remove("apps-only");
      setShowSideNav(true);
    }

    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);

    // Cleanup
    return () => {
      window.removeEventListener("load", viewChange);
      window.removeEventListener("resize", viewChange);
    };

  }, [window.location.pathname, themeState]); // eslint-disable-line react-hooks/exhaustive-deps

  // function to toggle sidebar
  const toggleSidebar = (e) => {
    e.preventDefault();
    if (visibility === false) {
      setVisibility(true);
    } else {
      setVisibility(false);
    }
  };

  // function to change the design view under 1200 px
  const viewChange = () => {
    if (window.innerWidth < 1200) {
      setMobileView(true);
    } else {
      setMobileView(false);
      setVisibility(false);
    }
  };

  const sidebarClass = classNames({
    "mobile-menu": mobileView,
    "nk-sidebar-active": visibility && mobileView,
  });

  return (
    <React.Fragment>
      {contextHolder}
      <Head title="Loading" />
      <div className="nk-app-root">
        <div className="nk-main">
          <div className="nk-wrap">
            <Header
              sidebarToggle={toggleSidebar}
              setVisibility={setVisibility}
              fixed={true}
              showSideNav={showSideNav}
              setThemeState={setThemeState}
              themeState={themeState}
              theme={themeState.header}
              />
            { showSideNav && 
              <SubscriptionAlert />
            }
            <NotificationManager />
            <div className="nk-content">
              <div className="container wide-xl">
                <div className="nk-content-inner">
                  { showSideNav && <Sidebar
                    sidebarToggle={toggleSidebar}
                    visibility={visibility}
                    mobileView={mobileView}
                    fixed
                    theme="light"
                    className={sidebarClass}
                  /> }
                  {visibility && mobileView && <div className="toggle-overlay" onClick={(e) => toggleSidebar(e)} />}
                  <div className="nk-content-body">
                    <Pages />
                    <Footer />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {
        !isOnline &&
        <div className="pmo-lv pmo-dark active bg-danger">
          {/* <a className="pmo-close" href="#"><em className="ni ni-cross"></em></a> */}
          <div className="pmo-wrap justify-content-center px-5 px-lg-3">
            <div className="pmo-text text-white text-center">
              <em className="ni ni-alert-c pe-2"></em> You are offline. Some features may not work.
            </div>
          </div>
        </div>
      }
    </React.Fragment>
  );
};
export default Layout;
