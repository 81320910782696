import React, { Suspense, useLayoutEffect } from "react";
import { Switch, Route } from "react-router-dom";
import { RedirectAs404 } from "../utils/Utils";

import MyBusinesses from "../pages/MyBusinesses";
import CreateBusiness from "../pages/CreateBusiness";
import Dashboard from "../pages/Dashboard";
import Sales from "../pages/Sales";
import SaleDetails from "../pages/SaleDetails";
import Analytics from "../pages/Analytics";
import Customers from "../pages/Customers";
import AddCustomer from "../pages/AddCustomer";
import ImportCustomers from "../pages/ImportCustomers";
import EditCustomer from "../pages/EditCustomer";
import CustomerProfile from "../pages/CustomerProfile";
import CustomerFeedback from "../pages/CustomerFeedback";
import MessageCustomers from "../pages/MessageCustomers";
import BuyMessages from "../pages/BuyMessages";
import Subscription from "../pages/Subscription";
import Products from "../pages/Products";
import AddProduct from "../pages/AddProduct";
import EditProduct from "../pages/EditProduct";
import ImportProducts from "../pages/ImportProducts";
import ProductDetails from "../pages/ProductDetails";
import Warehouses from "../pages/Warehouses";
import AddWarehouse from "../pages/AddWarehouse";
import EditWarehouse from "../pages/EditWarehouse";
import WarehouseProducts from "../pages/WarehouseProducts";
import AddWarehouseProducts from "../pages/AddWarehouseProducts";
import CreateInvoiceReceipt from "../pages/CreateInvoiceReceipt";
import EditSale from "../pages/EditSale";
import Expenses from "../pages/Expenses";
import { AddExpense } from "../pages/AddExpense";
import ExpenseDetails from "../pages/ExpenseDetails";
import { EditExpense } from "../pages/EditExpense";
import ProfitAndLoss from "../pages/ProfitAndLoss";
import Suppliers from "../pages/Suppliers";
import AddSupplier from "../pages/AddSupplier";
import EditSupplier from "../pages/EditSupplier";
import PurchaseOrders from "../pages/PurchaseOrders";
import { AddPurchaseOrder } from "../pages/AddPurchaseOrder";
import PurchaseOrderDetails from "../pages/PurchaseOrderDetails";
import { EditPurchaseOrder } from "../pages/EditPurchaseOrder";
import BusinessSettings from "../pages/BusinessSettings";
import InventoryCategory from "../pages/ProductCategory";
import DamagesLoss from "../pages/DamagesLoss";
import { AddDamagesLoss } from "../pages/AddDamagesLoss";
import ProductsPerformance from "../pages/ProductsPerformance";
import Orders from "../pages/Orders";
import Payouts from "../pages/Payouts";
import PayoutDetails from "../pages/PayoutDetails";
import Services from "../pages/Services";
import AddService from "../pages/AddService";
import EditService from "../pages/EditService";
import ImportServices from "../pages/ImportServices";
import ServicesPerformance from "../pages/ServicesPerformance";
import TeamMembers from "../pages/TeamMembers";
import AddTeamMembers from "../pages/AddTeamMembers";
import EditTeamMemberPermissions from "../pages/EditTeamMemberPermissions";
import TeamLogs from "../pages/TeamLogs";
import Invitations from "../pages/Invitations";
import Unauthorized from "../pages/Unauthorized";
import RawMaterials from "../pages/RawMaterials";
import AddRawMaterial from "../pages/AddRawMaterial";
import EditRawMaterial from "../pages/EditRawMaterial";
import ImportRawMaterials from "../pages/ImportRawMaterials";
import ManufacturingOrders from "../pages/ManufacturingOrders";
import { AddManufacturingOrder } from "../pages/AddManufacturingOrder";
import { EditManufacturingOrder } from "../pages/EditManufacturingOrder";
import ManufacturingOrderDetails from "../pages/ManufacturingOrdersDetails";
import Storefront from "../pages/Storefront";
import AbandonedCarts from "../pages/AbandonedCarts";
import Notifications from "../pages/Notifications";
import AccountSettings from "../pages/AccountSettings";
import AbandonedCartDetails from "../pages/AbandonedCartDetails";
import ExpensesAnalytics from "../pages/ExpensesAnalytics";
import SalesPayment from "../pages/SalesPayment";
import SalesPaymentList from "../pages/SalesPaymentList";
import { BusinessProfile } from "../pages/components/settings/BusinessProfile";
import { BusinessVerification } from "../pages/components/settings/BusinessVerification";
import { SMSSenderName } from "../pages/components/settings/SMSSenderName";
import { BusinessAPI } from "../pages/components/settings/BusinessAPI";
import { SalesSettings } from "../pages/components/settings/SalesSettings";
import { TaxSettings } from "../pages/components/settings/TaxSettings";
import { DeliveryPickupTable } from "../pages/components/settings/DeliveryPickupTable";
import { OnlinePayments } from "../pages/components/settings/OnlinePayments";
import { OfflinePayments } from "../pages/components/settings/OfflinePayments";
import { StorefrontSettings } from "../pages/components/settings/StorefrontSettings";
import { StorefrontFulfillment } from "../pages/components/settings/StorefrontFulfillment";
import { StorefrontAppearance } from "../pages/components/settings/StorefrontAppearance";
import CreateSaleOrder from "../pages/CreateSaleOrder";
import POS from "../pages/POS";
import OfflineSaleDetails from "../pages/OfflineSaleDetails";
import OfflineSales from "../pages/OfflineSales";


const Pages = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <Suspense fallback={<div />}>
      <Switch>

        <Route exact path={`${process.env.PUBLIC_URL}/my-businesses`} component={MyBusinesses}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/create-business`} component={CreateBusiness}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/invitations`} component={Invitations}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/account-settings`} component={AccountSettings}></Route>
        
        <Route exact path={`${process.env.PUBLIC_URL}/dashboard/b/:businessID`} component={Dashboard}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/analytics/b/:businessID`} component={Analytics}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/profit-and-loss/b/:businessID`} component={ProfitAndLoss}></Route>
        
        <Route exact path={`${process.env.PUBLIC_URL}/sales/b/:businessID`} component={Sales}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/sales/b/:businessID/offline`} component={OfflineSales}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/sales/b/:businessID/details/:saleID`} component={SaleDetails}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/sales/b/:businessID/offline-details/:saleID`} component={OfflineSaleDetails}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/sales/b/:businessID/edit/:saleID`} component={EditSale}></Route>
        
        <Route exact path={`${process.env.PUBLIC_URL}/create-invoice-receipt/b/:businessID`} component={CreateInvoiceReceipt}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/pos/b/:businessID`} component={POS}></Route>
        
        <Route exact path={`${process.env.PUBLIC_URL}/sales-payments-received/b/:businessID`} component={SalesPayment}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/sales-payments-received/b/:businessID/details/:paymentType`} component={SalesPaymentList}></Route>        
        
        
        <Route exact path={`${process.env.PUBLIC_URL}/orders/b/:businessID`} component={Orders}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/create-sale-order/b/:businessID`} component={CreateSaleOrder}></Route>
        
        <Route exact path={`${process.env.PUBLIC_URL}/payouts/b/:businessID`} component={Payouts}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/payouts/b/:businessID/details/:batchNumber`} component={PayoutDetails}></Route>
        
        <Route exact path={`${process.env.PUBLIC_URL}/customers/b/:businessID`} component={Customers}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/customers/b/:businessID/add`} component={AddCustomer}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/customers/b/:businessID/edit/:customerID`} component={EditCustomer}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/customers/b/:businessID/profile/:customerID`} component={CustomerProfile}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/customers/b/:businessID/import`} component={ImportCustomers}></Route>
        
        <Route exact path={`${process.env.PUBLIC_URL}/customer-feedback/b/:businessID`} component={CustomerFeedback}></Route>        
        <Route exact path={`${process.env.PUBLIC_URL}/message-customers/b/:businessID`} component={MessageCustomers}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/buy-messages/b/:businessID`} component={BuyMessages}></Route>
        
        <Route exact path={`${process.env.PUBLIC_URL}/products/b/:businessID`} component={Products}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/products/b/:businessID/add`} component={AddProduct}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/products/b/:businessID/import`} component={ImportProducts}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/products/b/:businessID/edit/:productID`} component={EditProduct}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/products/b/:businessID/details/:productID`} component={ProductDetails}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/products/b/:businessID/category`} component={InventoryCategory}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/products/b/:businessID/analytics`} component={ProductsPerformance}></Route>
        
        {/* <Route exact path={`${process.env.PUBLIC_URL}/warehouses/b/:businessID`} component={Warehouses}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/warehouses/b/:businessID/add`} component={AddWarehouse}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/warehouses/b/:businessID/:warehouseID/edit`} component={EditWarehouse}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/warehouses/b/:businessID/:warehouseID`} component={WarehouseProducts}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/warehouses/b/:businessID/:warehouseID/add`} component={AddWarehouseProducts}></Route> */}
        
        <Route exact path={`${process.env.PUBLIC_URL}/damages-loss/b/:businessID`} component={DamagesLoss}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/damages-loss/b/:businessID/add`} component={AddDamagesLoss}></Route>
        
        <Route exact path={`${process.env.PUBLIC_URL}/services/b/:businessID`} component={Services}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/services/b/:businessID/add`} component={AddService}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/services/b/:businessID/import`} component={ImportServices}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/services/b/:businessID/edit/:serviceID`} component={EditService}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/services/b/:businessID/analytics`} component={ServicesPerformance}></Route>
        
        <Route exact path={`${process.env.PUBLIC_URL}/raw-materials/b/:businessID`} component={RawMaterials}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/raw-materials/b/:businessID/add`} component={AddRawMaterial}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/raw-materials/b/:businessID/edit/:materialID`} component={EditRawMaterial}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/raw-materials/b/:businessID/import`} component={ImportRawMaterials}></Route>
        
        <Route exact path={`${process.env.PUBLIC_URL}/manufacturing-orders/b/:businessID`} component={ManufacturingOrders}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/manufacturing-orders/b/:businessID/add`} component={AddManufacturingOrder}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/manufacturing-orders/b/:businessID/edit/:orderID`} component={EditManufacturingOrder}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/manufacturing-orders/b/:businessID/details/:orderID`} component={ManufacturingOrderDetails}></Route>        
                
        <Route exact path={`${process.env.PUBLIC_URL}/expenses/b/:businessID`} component={Expenses}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/expenses/b/:businessID/add`} component={AddExpense}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/expenses/b/:businessID/details/:expenseID`} component={ExpenseDetails}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/expenses/b/:businessID/edit/:expenseID`} component={EditExpense}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/expenses/b/:businessID/analytics`} component={ExpensesAnalytics}></Route>

        <Route exact path={`${process.env.PUBLIC_URL}/suppliers/b/:businessID`} component={Suppliers}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/suppliers/b/:businessID/add`} component={AddSupplier}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/suppliers/b/:businessID/edit/:supplierID`} component={EditSupplier}></Route>

        <Route exact path={`${process.env.PUBLIC_URL}/purchase-orders/b/:businessID`} component={PurchaseOrders}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/purchase-orders/b/:businessID/add`} component={AddPurchaseOrder}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/purchase-orders/b/:businessID/details/:orderID`} component={PurchaseOrderDetails}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/purchase-orders/b/:businessID/edit/:orderID`} component={EditPurchaseOrder}></Route>
        
        <Route exact path={`${process.env.PUBLIC_URL}/team/b/:businessID`} component={TeamMembers}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/team/b/:businessID/add`} component={AddTeamMembers}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/team/b/:businessID/permissions/:memberID`} component={EditTeamMemberPermissions}></Route>
        
        <Route exact path={`${process.env.PUBLIC_URL}/team-activity-logs/b/:businessID`} component={TeamLogs}></Route>
        
        <Route exact path={`${process.env.PUBLIC_URL}/business-profile/b/:businessID`} component={BusinessProfile}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/business-verification/b/:businessID`} component={BusinessVerification}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/sms-sender-name/b/:businessID`} component={SMSSenderName}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/api-key/b/:businessID`} component={BusinessAPI}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/sales-settings/b/:businessID`} component={SalesSettings}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/tax-profiles/b/:businessID`} component={TaxSettings}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/delivery-pickup/b/:businessID`} component={DeliveryPickupTable}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/online-payment/b/:businessID`} component={OnlinePayments}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/offline-payment/b/:businessID`} component={OfflinePayments}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/storefront-settings/b/:businessID`} component={StorefrontSettings}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/storefront-fulfillment-settings/b/:businessID`} component={StorefrontFulfillment}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/storefront-appearance-settings/b/:businessID`} component={StorefrontAppearance}></Route>
        
        <Route exact path={`${process.env.PUBLIC_URL}/storefront/b/:businessID`} component={Storefront}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/storefront/b/:businessID/abandoned-carts`} component={AbandonedCarts}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/storefront/b/:businessID/abandoned-carts/details/:cartID`} component={AbandonedCartDetails}></Route>
          
        <Route exact path={`${process.env.PUBLIC_URL}/notifications/b/:businessID`} component={Notifications}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/subscription/b/:businessID`} component={Subscription}></Route>

        <Route exact path={`${process.env.PUBLIC_URL}/unauthorized/b/:businessID`} component={Unauthorized}></Route>
        
        <Route component={RedirectAs404}></Route>
      </Switch>
    </Suspense>
  );
};
export default Pages;
