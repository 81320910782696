import React, { useState, useEffect } from "react";
import Content from "../layout/content/Content";
import Head from "../layout/head/Head";
import { DropdownToggle, DropdownMenu, UncontrolledDropdown, DropdownItem, Spinner, Alert } from "reactstrap";
import {
  Block,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
  Row,
  Col,
  PreviewAltCard,
} from "../components/Component";
import { baseURLs } from '../utils/Constants';
import { getAxiosHeaders } from "../utils/Utils";
import { Link } from "react-router-dom";
import classNames from "classnames";
import axios from 'axios';
import { OfflineSalesTable } from "./components/tables/OfflineSalesTable";

const OfflineSales = ({ history, match }) => {
  const [sm, updateSm] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingUsage, setLoadingUsage] = useState(true);
  const [businessID, setBusinessID] = useState("");
  const [businessInfo, setBusinessInfo] = useState({});

  useEffect(() => {
    setLoading(true);
    const businessID = parseInt(match.params.businessID, 10);
    if ( !Number.isInteger(businessID) ) {
      history.push(`${process.env.PUBLIC_URL}/not-found`);
      return;      
    } 

    localStorage.setItem('current_business_id', businessID);
    let businesses = JSON.parse(localStorage.getItem('my_businesses'));
    let currentBusiness = businesses?.find((business) => business.business_id == businessID);
    
    if(currentBusiness == undefined || null || ""){
      history.push(`${process.env.PUBLIC_URL}/not-found`);
      return;
    }

    setBusinessInfo(currentBusiness);
    setBusinessID(businessID);
    setLoading(false);
  }, [match.params.businessID]);

  return (<React.Fragment>
    <Head title="Sales" />
    <Content>
    {
      loading ?
      <Block className="nk-block-middle nk-auth-body text-center wide-xs">
        <div className="inner-pre-loader">
          <Spinner  color="dark" />          
        </div>
      </Block>
      :
      <>
      <BlockHead size="sm">
        <div className="nk-block-between mb-4">
          <BlockHeadContent>
            <BlockDes className="text-soft">
              <p>{businessInfo.name}</p>
            </BlockDes>
            <BlockTitle page tag="h3">
              Offline Sales
            </BlockTitle>
            
          </BlockHeadContent>
          <BlockHeadContent>
            <div className="toggle-wrap nk-block-tools-toggle">
              <Button
                className={`btn-icon btn-trigger toggle-expand me-n1 ${sm ? "active" : ""}`}
                onClick={() => updateSm(!sm)}
              >
                <Icon name="more-v"></Icon>
              </Button>
              <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                <ul className="nk-block-tools g-3">
                  <li>
                    <Link to={`${process.env.PUBLIC_URL}/sales/b/${businessID}`}>
                      <Button color="primary">
                        <Icon name="coins"></Icon>
                        <span>View Sales</span>
                      </Button>
                    </Link>
                  </li>
                  <li>
                    <UncontrolledDropdown>
                      <DropdownToggle className="dropdown-toggle btn btn-outline-light" color="none">
                        <Icon name="more-h"></Icon>
                      </DropdownToggle>
                      <DropdownMenu end>
                        <ul className="link-list-opt no-bdr">
                          {/* <li>
                            <Link to={`${process.env.PUBLIC_URL}/sales/b/${businessID}/delivery-stats`}>
                              <Icon name="location"></Icon>
                              <span>Pick-up an Delivery Stats</span>
                            </Link>
                          </li> */}
                          <li>
                            <Link to={`${process.env.PUBLIC_URL}/sales-settings/b/${businessID}`}>
                              <Icon name="setting"></Icon>
                              <span>Sales Settings</span>
                            </Link>
                          </li>
                        </ul>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </li>
                </ul>
              </div>
            </div>
          </BlockHeadContent>
        </div>
      </BlockHead>

      <OfflineSalesTable history={history} businessID={businessID} currency={businessInfo.currency} />
      </>
    }
    </Content>
    
  </React.Fragment>)
}

export default OfflineSales;