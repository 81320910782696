import React, { useEffect, useState } from "react";
import classNames from "classnames";
import Numeral from 'react-numeral';
import moment from "moment";
import { 
  UncontrolledPopover, 
  PopoverHeader, 
  PopoverBody, 
  Alert,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import { getAxiosHeaders, moneyToFloat, orderStatusOptions } from "../../../utils/Utils";
import {
  Icon,
  Button
} from "../../../components/Component";
import { baseURLs } from '../../../utils/Constants';
import axios from 'axios';
import { LinkItUrl } from 'react-linkify-it';
import { Link, useHistory } from "react-router-dom";
import { AutoComplete, Empty, Input, Select, Space, InputNumber, Divider, Spin, Form } from 'antd';
import GooglePlacesInput from "../GooglePlacesInput";
import BalancePaymentForm from "./BalancePaymentForm";
import OrderInfo from "./OrderInfo";
import Footer from "./Footer";
import OfflineBalancePaymentForm from "./OfflineBalancePayment";
import { deleteOfflineSale, deleteOfflineSalesByIds } from "../../../utils/OfflineDB";
import { AlertModal, LoadingModal, SuccessModal } from "../AlertModals";


const { Option } = Select;

const OfflineBody = ({saleInfo, setSaleInfo, view, updateSales, thermalPrintSale, ...props }) => {
  let history = useHistory();
  const businessID = saleInfo.business_id;
  const [errorMessage, setErrorMessage] = useState("");
  const [activeModal, setActiveModal] = useState(null);

  const toggleModal = (modal) => {
    if (activeModal === modal) {
      setActiveModal(null);
    } else {
      setActiveModal(modal);
    }
  };

  const deleteSale = async() => {
    toggleModal('deleteLoadingModal');
    await deleteOfflineSale(saleInfo.sale_id);
    toggleModal('successDeleteModal');
    setTimeout(() => {
      updateSales(saleInfo.sale_id);
    }, 1000);
  }

  return (
    <>
      {/* items section*/}
      <div className="fm-content" key="sale_details_body">
        <ul className="nk-top-products">
          {
            saleInfo.items.map((data, index) => {
              let details = data.description && data.item_type === 'product' ?
              <> • <Button type="button" id={`item_description_popover`} className="btn-round btn-icon text-end p-0 text-muted" color="muted" size="sm">
                Details
              </Button>
              <UncontrolledPopover target={`item_description_popover`} placement="bottom" trigger="focus">
                <PopoverBody>
                  <p>{data.description}</p>
                </PopoverBody>
              </UncontrolledPopover> </> : ``;

              let quantity = data.item_type === 'product' ? `• Qty: ${data.quantity}` : data.quantity > 1 ? `• Qty: ${data.quantity}` : '';

              return <> 
                      <li className="item" key={index}>                        
                        <div className="info">
                          <div className="title">{data.item_name}</div>
                          { data.description && data.item_type === 'service' && <div className="price text-dark">{data.description}</div> }
                          <div className="price mt-2">{saleInfo.currency} {data.price} {quantity} {details}</div>                          
                        </div>
                        <div className="total">
                          <div className="amount"><small className="text-muted">{saleInfo.currency}</small>
                            <Numeral value={data.total.toString()} format={"0,0.00"} />
                          </div>
                        </div>
                      </li>                      
                    </>
            })
          }

          {
            saleInfo.delivery_pickup && 
            <>
              <li className="item" key="delivery_pickup">
                {
                  saleInfo.delivery_pickup.location_type === 'delivery' ?
                  <div className="fm-count"><em className="icon ni ni-truck fs-22px"></em></div>
                  :
                  <div className="fm-count"><em className="icon ni ni-location fs-22px"></em></div>
                }
                
                <div className="info">
                  {
                    saleInfo.delivery_pickup.location_type === 'delivery' ?
                    <div className="title">Delivery</div>
                    :
                    <div className="title">Pick Up</div>
                  }

                  <div className="price text-dark">{saleInfo.delivery_pickup.location_address}</div>
                  <div className="price">{saleInfo.delivery_pickup.location_landmark}</div>
                </div>
                <div className="total">
                  <div className="amount"><small className="text-muted">{saleInfo.currency}</small> {saleInfo.delivery_pickup.location_cost}</div>
                </div>
              </li>
            </>
          }
        </ul>
      </div>

      {/* summary section */}
      <div className="fm-content bg-lighter">
        <div className="row">
          <div className="col-md-12">
            <div className="nk-iv-scheme-details">
              <ul className="nk-iv-wg3-list w-100">
                <li className="px-0 py-2 fw-medium">
                  <div className="sub-text fs-16px">Sub Total</div>
                  <div className="nk-wg-action-content p-0">
                    <h4 className="amount fs-16px">
                      <small className="text-muted me-1">{saleInfo.currency}</small> 
                      <Numeral value={saleInfo.sub_total.toString()} format={"0,0.00"} />
                    </h4>
                  </div>
                </li>
                <li className="px-0 py-2 d-block">
                  {
                   saleInfo.taxes &&
                    <>
                      {
                        saleInfo.taxes.length > 0 &&
                        <div className="mb-2">
                          {
                            saleInfo.taxes.map((tax, index) => {
                              return(
                                <div className="d-flex justify-content-between mb-1" key={index}>
                                  <div className="sub-text fs-14px">{tax.tax} <small>( {tax.percentage}% )</small></div>
                                  <div className="nk-wg-action-content p-0">
                                    <h4 className="amount fs-14px">
                                      <small className="text-muted me-1">{saleInfo.currency}</small> 
                                      <Numeral value={tax.amount.toString()} format={"0,0.00"} />
                                    </h4>
                                  </div>
                                </div>
                              )
                            })
                          }
                        </div>
                      }
                    </>

                  }
                  

                  <div className="d-flex justify-content-between fw-medium">
                    <div className="sub-text fs-14px">Total Tax</div>
                    <div className="nk-wg-action-content p-0">
                      <h4 className="amount fs-14px">
                        <small className="text-muted me-1">{saleInfo.currency}</small> 
                        <Numeral value={saleInfo.tax_amount.toString()} format={"0,0.00"} />
                      </h4>
                    </div>
                  </div>
                </li>
                <li className="px-0 py-2">
                  <div className="sub-text fs-14px">Discount</div>
                  <div className="nk-wg-action-content p-0">
                    <h4 className="amount fs-14px">
                      <small className="text-muted me-1">- {saleInfo.currency}</small>
                      <Numeral value={saleInfo.discount.discount_amount.toString()} format={"0,0.00"} />
                      <small className="text-muted"> ({saleInfo.discount.discount_percentage}%)</small>
                    </h4>
                  </div>
                </li>
                <li className="px-0 py-2 fw-bold">
                  <div className="sub-text fs-18px">Total</div>
                  <div className="nk-wg-action-content p-0">
                    <h4 className="amount fs-18px">
                      <small className="text-muted me-1">{saleInfo.currency}</small> 
                      <Numeral value={saleInfo.total_amount.toString()} format={"0,0.00"} />
                    </h4>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-md-12 mt-3">
            <div className="nk-iv-scheme-details">
              <ul className="nk-iv-wg3-list w-100">
                <li className="px-0 py-2">
                  <div className="sub-text fs-14px">Amount Received</div>
                  <div className="nk-wg-action-content p-0">
                    <h4 className="amount fs-14px">
                      <small className="text-muted me-1">{saleInfo.currency}</small> 
                      <Numeral value={saleInfo.amount_received.toString()} format={"0,0.00"} />
                    </h4>
                  </div>
                </li>
                <li className="px-0 py-2">
                  <div className="sub-text fs-14px">Amount Paid</div>
                  <div className="nk-wg-action-content p-0">
                    <h4 className="amount fs-14px">
                      <small className="text-muted me-1">{saleInfo.currency}</small> 
                      <Numeral value={saleInfo.amount_paid.toString()} format={"0,0.00"} />
                    </h4>
                  </div>
                </li>
                <li className="px-0 py-2 fw-bold">
                  <div className={`sub-text fs-18px ${['invoice', 'quote'].includes(saleInfo.status) ? `text-danger`: ``}`}>Balance Due</div>
                  <div className="nk-wg-action-content p-0">
                    <h4 className={`amount fs-18px ${['invoice', 'quote'].includes(saleInfo.status) ? `text-danger`: ``}`}>
                      <small className={`${['invoice', 'quote'].includes(saleInfo.status) ? `text-danger`: `text-muted`} me-1`}>{saleInfo.currency}</small>
                      <Numeral value={saleInfo.balance_due.toString()} format={"0,0.00"} />
                    </h4>
                  </div>
                </li>
                <li className="px-0 py-2">
                  <div className="sub-text fs-14px">Change</div>
                  <div className="nk-wg-action-content p-0">
                    <h4 className={`amount fs-14px ${parseFloat(saleInfo.change) > 0 ? `text-success`: ``}`}>
                      <small className={`${parseFloat(saleInfo.change) > 0 ? `text-success`: `text-muted`} me-1`}>{saleInfo.currency}</small>
                      <Numeral value={saleInfo.change.toString()} format={"0,0.00"} />
                    </h4>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          {
            saleInfo.status === 'receipt' && 
            <div className="col-md-12">
              <div className="alert alert-fill alert-success alert-icon"><em className="icon ni ni-check-circle"></em> <strong>Receipt paid in full</strong></div>
            </div>
          }

          {
            ( saleInfo.status === 'invoice' || saleInfo.status === 'quote' )  && 
            <div className="col-md-12">
              <div className="alert alert-fill alert-danger alert-icon mb-3">
                <em className="icon ni ni-alert-circle"></em> 
                <b>Due Date: </b>
                <span>
                  {moment(saleInfo.due_date).format("Do MMM, YYYY h:mm a")}
                </span>
              </div>
            </div>
          }

          {/* update balance component */}
          {
            ( saleInfo.status === 'invoice' || saleInfo.status === 'quote' ) &&
            <OfflineBalancePaymentForm saleInfo={saleInfo} setSaleInfo={setSaleInfo} />
          }

          {
            saleInfo.note &&              
            <div className="col-md-12 mt-5">
              <hr className="mb-5" />
              <p className="mb-1"><strong>Note</strong></p>
              <LinkItUrl>
              <p style={{whiteSpace: "pre-wrap"}}>
                {saleInfo.note}
              </p>
              </LinkItUrl>
            </div>
          }

        </div>
      </div>

      {/* footer section */}
      <div className="fm-footer">
        <div className="fm-action text-center">
          <ul className="d-flex g-3 justify-content-center">
            <li>
              <button className="btn btn-lg btn-primary" onClick={() => thermalPrintSale()}>
                <span>Print</span>
                <em className="icon ni ni-printer"></em>
              </button>
            </li>
            <li>
              <button className="btn btn-lg btn-danger" onClick={() => toggleModal("deleteAlertModal")}>
                <span>Delete</span>
              </button>
            </li>
          </ul>
        </div>
      </div>

      {
        activeModal === "deleteAlertModal" &&
        <AlertModal showModal={true} toggleModal={() => toggleModal('deleteAlertModal')}
          headerText={`Delete Offline Sale`} 
          descriptionText={`Are you sure you want to delete this offline sale. This action can not be reverted.`}
          leftButtonText={`Delete`}
          leftButtonOnClick={deleteSale} />
      }

      { activeModal === "deleteLoadingModal" && <LoadingModal showModal={true} headerText={`Deleting`} descriptionText={`Please wait while the offline sale is being deleted`} /> }
      
      { 
        activeModal === "successDeleteModal" &&
        <SuccessModal showModal={true} toggleModal={() => toggleModal(null)}
          headerText={`Deleted`} 
          descriptionText={`The offline sale has been deleted successfully.`} 
        />
      }
      
      {
        activeModal === "deleteErrorModal" &&
        <AlertModal showModal={true} toggleModal={() => toggleModal(null)}
          headerText={"Error"} descriptionText={errorMessage}                       
        />
      }
    </>
  );
};


export default OfflineBody;