import React, { useState, useEffect } from "react";
import { GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';
import { setCookie, getCookie } from "../../utils/Utils";
import axios from 'axios';
import { baseURLs } from '../../utils/Constants';
import Logo from "../../layout/logo/Logo";
import { Input, Form } from 'antd';
import PageContainer from "../../layout/page-container/PageContainer";
import Head from "../../layout/head/Head";
import AuthFooter from "./AuthFooter";
import {
  Block,
  BlockContent,
  BlockHead,
  BlockTitle,
  BlockDes,
  Button,
  Icon,
  PreviewCard,
} from "../../components/Component";
import { Spinner, Alert } from "reactstrap";
import { Link } from "react-router-dom";
import GoogleLoginButton from "../components/GoogleLogin";

const Login = ({ history }) => {
  const [loadingGoogleLogin, setLoadingGoogleLogin] = useState(false);
  const [loading, setLoading] = useState(false);
  const [country, setCountry] = useState("Ghana");
  const [form] = Form.useForm();
  const [formError, setFormError] = useState("");

  const handleFormSubmit = (_formData) => {
    setLoading(true);
    setFormError("");

    axios.post(baseURLs.API_URL + "/auth/login", {
      email: _formData.email,
      password: _formData.password,
      source: "web"
    })
		.then((response) => {
      let responseInfo = response.data;
      console.log(responseInfo)
      if(responseInfo.data.user_info.account_verification_status){
				let expirationDate = new Date();
        let validDays = 90;
        expirationDate.setTime(expirationDate.getTime() + (validDays*24*60*60*1000));

        setCookie('access_token', responseInfo.data.access_token, expirationDate)
        localStorage.setItem('userInfo', JSON.stringify(responseInfo.data.user_info));
        localStorage.setItem('justLoggedIn', true);
        // history.push(`${process.env.PUBLIC_URL}/my-businesses`);
        window.location = `${process.env.PUBLIC_URL}/my-businesses`;
        
      }else{
        window.location = `${process.env.PUBLIC_URL}/pending-account-verification`;
      }

		}).catch((error) => {
      setLoading(false);
      setFormError("Invalid login credentials");
		});
  };

  const onFinishFailed = (errorInfo) => {
    window.scrollTo({top: 0, behavior: 'smooth'});
  };

  useEffect(() => {
    if(localStorage.getItem("country") === null){
      axios.get('https://ipapi.co/json/').then((response) => {
        let responseInfo = response.data;
        setCountry(responseInfo.country_name);
      }).catch((error) => {
        console.log(error);
        setCountry("Ghana");
      });
    } else {
      setCountry(localStorage.getItem("country"));
    }
  }, []);

  return (
    <React.Fragment>
      <Head title="Login" />
      <PageContainer>
        <Block className="nk-block-middle nk-auth-body  wide-xs">
          <div className="brand-logo pb-4 text-center">
            <Logo />
          </div>

          <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
            <BlockHead>
              <BlockContent className="text-center">
                <BlockTitle tag="h4">Welcome</BlockTitle>
                <BlockDes>
                  <p>Sign in to your account</p>
                </BlockDes>
                <GoogleOAuthProvider clientId={baseURLs.GOOGLE_CLIENT_ID}>
                  <GoogleLoginButton 
                    buttonText="Sign in with Google"
                    loadingGoogleLogin={loadingGoogleLogin} 
                    setLoadingGoogleLogin={setLoadingGoogleLogin} 
                    setFormError={setFormError} 
                    country={country} 
                  />
                </GoogleOAuthProvider>
                <div className="text-center pt-4 pb-3">
                  <h6 className="overline-title overline-title-sap">
                    <span>or sign in with email</span>
                  </h6>
                </div>
              </BlockContent>
            </BlockHead>
            {formError && (
              <div className="mb-3">
                <Alert color="danger" className="alert-icon">
                  {" "}
                  <Icon name="alert-circle" /> {formError}{" "}
                </Alert>
              </div>
            )}
            <Form form={form} className="is-alter"
              onFinish={handleFormSubmit}
              onFinishFailed={onFinishFailed}>
              <div className="form-group">
                <Form.Item>
                  <div className="form-label-group">
                    <label className="form-label" htmlFor="email">
                      Email
                    </label>
                  </div>
                  <div className="form-control-wrap">
                    <Form.Item name={'email'}
                      noStyle
                      rules={[
                        {required: true, message: 'Email is required'},
                        {type: "email", message: 'Email is invalid'}
                      ]}>
                        <Input size="large"
                          type="email" className="form-control form-control-lg" 
                          placeholder="Enter your email"
                        />
                    </Form.Item>
                  </div>
                </Form.Item>
              </div>
              <div className="form-group">
                <Form.Item>
                  <div className="form-label-group">
                    <label className="form-label" htmlFor="password">
                      Password
                    </label>
                    <Link className="link link-primary link-sm" to={`${process.env.PUBLIC_URL}/forgot-password`}>
                      Forgot Password?
                    </Link>
                  </div>
                  <div className="form-control-wrap">
                    <Form.Item name={'password'}
                      noStyle
                      rules={[
                        {required: true, message: 'Password is required'}
                      ]}>
                      <Input.Password size="large"
                        type="password" placeholder="Enter your password"
                      />
                    </Form.Item>
                  </div>
                </Form.Item>
              </div>
              <div className="form-group mt-5">
                <Button size="lg" className="btn-block" type="submit" color="primary" disabled={loadingGoogleLogin}>
                  {loading ? <Spinner size="sm" color="light" /> : "Sign in"}
                </Button>
              </div>
            </Form>
            <div className="form-note-s2 text-center pt-4">
              {" "}
              Don't have an account? <Link to={`${process.env.PUBLIC_URL}/register`}>Sign Up</Link>
            </div>
          </PreviewCard>
        </Block>
        <AuthFooter />
      </PageContainer>
    </React.Fragment>
  );
};
export default Login;
