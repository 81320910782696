import { Redirect } from "react-router-dom";
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import { countryPhoneCodes } from '../utils/Countries';
import moment from "moment";
import numeral from "numeral";

//url for production
export var url = "";
if (process.env.NODE_ENV === "development") {
  url = "";
} else {
  url = window.location.host.split("/")[1];
  if (url) {
    url = `/${window.location.host.split("/")[1]}`;
  } else url = process.env.PUBLIC_URL; /// ADD YOUR CPANEL SUB-URL
}

//Function to validate and return errors for a form
export const checkForm = (formData) => {
  let errorState = {};
  Object.keys(formData).forEach((item) => {
    if (formData[item] === null || formData[item] === "") {
      errorState[item] = "This field is required";
    }
  });
  return errorState;
};

//Function that returns the first or first two letters from a name
export const findUpper = (string) => {
  let extractedString = [];

  for (var i = 0; i < string.length; i++) {
    // if (string.charAt(i) === string.charAt(i).toUpperCase() && string.charAt(i) !== " ") {
    if (string.charAt(i) !== " ") {
      extractedString.push(string.charAt(i).toUpperCase());
    }
  }
  if (extractedString.length > 1) {
    return extractedString[0] + extractedString[1];
  } else {
    return extractedString[0];
  }
};

//Function that calculates the from current date
export const setDeadline = (days) => {
  let todayDate = new Date();
  var newDate = new Date(todayDate);
  newDate.setDate(newDate.getDate() + days);
  return newDate;
};

// Function to structure date ex : Jun 4, 2011;
export const getDateStructured = (date) => {
  let d = date.getDate();
  let m = date.getMonth();
  let y = date.getFullYear();
  let final = monthNames[m] + " " + d + ", " + y;
  return final;
};

// Function to structure date ex: YYYY-MM-DD
export const setDateForPicker = (rdate) => {
  let d = rdate.getDate();
  d < 10 && (d = "0" + d);
  let m = rdate.getMonth() + 1;
  m < 10 && (m = "0" + m);
  let y = rdate.getFullYear();
  rdate = y + "-" + m + "-" + d;

  return rdate;
};

// Set deadlines for projects
export const setDeadlineDays = (deadline) => {
  var currentDate = new Date();
  var difference = deadline.getTime() - currentDate.getTime();
  var days = Math.ceil(difference / (1000 * 3600 * 24));
  return days;
};

//Date formatter function
export const dateFormatterAlt = (date, reverse) => {
  let d = date.getDate();
  let m = date.getMonth();
  let y = date.getFullYear();
  reverse ? (date = m + "-" + d + "-" + y) : (date = y + "-" + d + "-" + m);
  return date;
};

//Date formatter function
export const dateFormatter = (date, reverse, string) => {
  var dateformat = date.split("-");
  //var date = dateformat[1]+"-"+dateformat[2]+"-"+dateformat[0];
  reverse
    ? (date = dateformat[2] + "-" + dateformat[0] + "-" + dateformat[1])
    : (date = dateformat[1] + "-" + dateformat[2] + "-" + dateformat[0]);

  return date;
};

//todays Date
export const todaysDate = new Date();

//current Time
export const currentTime = () => {
  var hours = todaysDate.getHours();
  var minutes = todaysDate.getMinutes();
  var ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
};

//Percentage calculation
export const calcPercentage = (str1, str2) => {
  let result = Number(str2) / Number(str1);
  result = result * 100;
  return Math.floor(result);
};

export const truncate = (str, n) => {
  return str.length > n ? str.substr(0, n - 1) + " " + truncate(str.substr(n - 1, str.length), n) : str;
};

export const RedirectAs404 = ({ location }) => (
  <Redirect to={Object.assign({}, location, { state: { is404: true } })} />
);

// returns upload url
export const getUploadParams = () => {
  return { url: "https://httpbin.org/post" };
};

// Converts KB to MB
export const bytesToMegaBytes = (bytes) => {
  let result = bytes / (1024 * 1024);
  return result.toFixed(2);
};

export const bulkActionOptions = [
  { value: "suspend", label: "Suspend User" },
  { value: "delete", label: "Delete User" },
];

export const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const getPhoneCodeAndNumber = (phoneNumber) => {
  // Remove any non-digit characters and the leading '+'
  const digitsOnly = phoneNumber.replace(/[^\d]/g, '');

  let phoneCode = '';
  let phoneNumberOnly = '';

  // sort country codes in descending order
  let _countryPhoneCodes = countryPhoneCodes;
  _countryPhoneCodes.sort((a, b) => {
    const aValue = parseInt(a.value.replace('+', ''), 10);
    const bValue = parseInt(b.value.replace('+', ''), 10);
    return bValue - aValue;
  });

  // Loop through the list of country codes to find a match
  for (let code of _countryPhoneCodes) {
    code = code.value.replace('+', '');
    if (digitsOnly.startsWith(code)) {      
      phoneCode = `+${code}`;
      phoneNumberOnly = digitsOnly.substring(code.length);
      break;
    }
  }

  return {
    phoneCode,
    phoneNumber: phoneNumberOnly
  };
}

export const getQueryParams = (url) => {
  const queryParams = {};
  const urlObj = new URL(url);
  const urlSearchParams = new URLSearchParams(urlObj.search);

  for (const [key, value] of urlSearchParams.entries()) {
    queryParams[key] = value;
  }

  return queryParams;
};

export const getCookie = (name) => {
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith(name + '=')) {
      return cookie.substring(name.length + 1);
    }
  }
  return null;
};

export const setCookie = (name, value, days = 60) => {
  const expirationDate = new Date();
  expirationDate.setDate(expirationDate.getDate() + days);
  const cookie = `${name}=${value};expires=${expirationDate.toUTCString()};path=/`;
  document.cookie = cookie;
};

export const deleteCookie = (name) => {
  document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;`;
};

export const getAuthStatus = () => {
  let access_token = getCookie('access_token');
  let userInfo = localStorage.getItem('userInfo');
  try {
    userInfo = JSON.parse(userInfo);
  } catch (error) {
    userInfo = {};
  }

  let is_logged_in = false;
  if(access_token?.length > 0 && userInfo?.account_verification_status === true){
    is_logged_in = true;
  }

  return is_logged_in;
};

export const getAxiosHeaders = () => {
  let access_token = getCookie('access_token');
  return { headers: {"Authorization" : `Bearer ${access_token}`} }
}

export const getAxiosAdminHeaders = () => {
  let access_token = getCookie('access_token');
  return { headers: {"Authorization" : `Bearer ${access_token}`} }
}

export const getAxiosAdminUploadHeaders = () => {
  let access_token = getCookie('access_token');
  return { 
      headers: {
          "Authorization" : `Bearer ${access_token}`, 
          "content-type": "multipart/form-data"
      } 
  }
}

export const getAxiosUploadHeaders = () => {
  let access_token = getCookie('access_token');
  return { 
      headers: {
          "Authorization" : `Bearer ${access_token}`, 
          "content-type": "multipart/form-data"
      } 
  }
}

export const recommendedColors = () => {
  const colors = [
    '#000000',
    '#F5222D',
    '#FA8C16',
    '#FADB14',
    '#52C41A',
    '#13A8A8',
    '#1677FF',
    '#722ED1',
    '#EB2F96',
  ]

  return colors;
}

export const orderStatusOptions = [
  {value: 'outstanding', label: 'Outstanding'},
  {value: 'pending_payment', label: 'Pending Payment'},
  {value: 'processing', label: 'Processing'},
  {value: 'on_hold', label: 'On Hold'},
  {value: 'ready_for_pickup', label: 'Ready for Pickup'},
  {value: 'in_transit', label: 'In Transit'},
  {value: 'delivered', label: 'Delivered'},
]

export const manufacturingOrderStatusOptions = [
  {value: 'in_progress', label: 'In Progress'},
  {value: 'on_hold', label: 'On Hold'},
  {value: 'ready_for_pickup', label: 'Ready for Pickup'},
  {value: 'in_transit', label: 'In Transit'},
  {value: 'delivered', label: 'Delivered'},
]

export const phoneNumberFormatter = (phoneNumber) => {
  const parsedPhoneNumber = parsePhoneNumberFromString(phoneNumber);
  return parsedPhoneNumber?.formatInternational() || phoneNumber;
};

export const moneyToFloat = (amount) => {
  // Remove commas from the string
  const noCommas = amount.toString().replace(/,/g, '');

  // Convert string to float
  return parseFloat(noCommas);
}

export const moneyFormat = ( amount ) => {
  return numeral(amount).format('0,0.00');
}

export const formatTo2DP = ( amount ) => {
  return parseFloat((Math.round(amount * 100) / 100).toFixed(2));
}

export const getValueFromJSONArray = (jsonArray, key, value) => {
  const foundObject = jsonArray.find(obj => obj[key] === value);
  return foundObject || null;
}

export const goBack = ({history, link}) => {
  if (history.length > 2) {
    history.goBack();
  } else {
    history.push(process.env.PUBLIC_URL + link); // Replace with your own default route
  }
};

export const setLSData = (key, value) => {
  typeof value === 'object'
    ? localStorage.setItem(key, JSON.stringify(value))
    : localStorage.setItem(key, value);
}

export const getLSData = (key) => {
  try {
    return JSON.parse(localStorage.getItem(key));
  } catch {
    return localStorage.getItem(key);
  }
}

export const getTextColorBaseOnTheme = (color) => {
  // Convert hex color to RGB
  const rgb = color.charAt(0) === '#' ? color.substring(1, 7) : color;
  const r = parseInt(rgb.substring(0, 2), 16); // hexToR
  const g = parseInt(rgb.substring(2, 4), 16); // hexToG
  const b = parseInt(rgb.substring(4, 6), 16); // hexToB

  // Calculate the brightness
  const brightness = (r * 299 + g * 587 + b * 114) / 1000;
  return brightness > 127.5 ? '#000000' : '#ffffff';
}

export const permissionsList = [
  { source: 'Analytics & Reports', permission: 'Access Overview', permission_code: 'access_overview' },
  { source: 'Analytics & Reports', permission: 'Access Analytics', permission_code: 'access_analytics' },
  { source: 'Analytics & Reports', permission: 'Access Dashboard', permission_code: 'access_dashboard' },
  { source: 'Business Settings', permission: 'Access Business Settings', permission_code: 'access_business_settings' },
  { source: 'Business Settings', permission: 'Edit/Update Business Settings', permission_code: 'edit_update_business_settings' },
  { source: 'Customers', permission: 'Access Customers', permission_code: 'access_customers' },
  { source: 'Customers', permission: 'Add Customers', permission_code: 'add_customers' },
  { source: 'Customers', permission: 'Message Customers', permission_code: 'message_customers' },
  { source: 'Customers', permission: 'Edit/Update Customers', permission_code: 'edit_update_customers' },
  { source: 'Expenses', permission: 'Access Expenses', permission_code: 'access_expenses' },
  { source: 'Expenses', permission: 'Access Suppliers', permission_code: 'access_suppliers' },
  { source: 'Expenses', permission: 'Access Purchase Orders', permission_code: 'access_purchase_orders' },
  { source: 'Expenses', permission: 'Add Expenses', permission_code: 'add_expenses' },
  { source: 'Expenses', permission: 'Add Suppliers', permission_code: 'add_suppliers' },
  { source: 'Expenses', permission: 'Add Purchase Orders', permission_code: 'add_purchase_orders' },
  { source: 'Expenses', permission: 'Edit/Update Expenses', permission_code: 'edit_update_expenses' },
  { source: 'Expenses', permission: 'Edit/Update Purchase Orders', permission_code: 'edit_update_purchase_orders' },
  { source: 'Expenses', permission: 'Edit/Update Suppliers', permission_code: 'edit_update_suppliers' },
  { source: 'Inventory', permission: 'Access Products', permission_code: 'access_inventory' },
  { source: 'Inventory', permission: 'Access Raw Materials', permission_code: 'access_raw_materials' },
  { source: 'Inventory', permission: 'Access Damages & Loss', permission_code: 'access_damages_loss' },
  { source: 'Inventory', permission: 'Add Products', permission_code: 'add_products' },
  { source: 'Inventory', permission: 'Add Raw Materials', permission_code: 'add_raw_materials' },
  { source: 'Inventory', permission: 'Add Damages & Loss', permission_code: 'add_damages_loss' },
  { source: 'Inventory', permission: 'Edit/Update Products', permission_code: 'edit_update_products' },
  { source: 'Inventory', permission: 'Edit/Update Damages & Loss', permission_code: 'edit_update_damages_loss' },
  { source: 'Inventory', permission: 'Edit/Update Raw Materials', permission_code: 'edit_update_raw_materials' },
  { source: 'Sales & Orders', permission: 'Access Sales', permission_code: 'access_sales' },
  { source: 'Sales & Orders', permission: 'Access Sales Payment Received', permission_code: 'access_sales_payment' },
  { source: 'Sales & Orders', permission: 'Access Orders', permission_code: 'access_orders' },
  { source: 'Sales & Orders', permission: 'Access Payouts', permission_code: 'access_payouts' },
  { source: 'Sales & Orders', permission: 'Access Storefront', permission_code: 'access_storefront' },
  { source: 'Sales & Orders', permission: 'Create Invoice / Receipt', permission_code: 'record_sales' },
  { source: 'Sales & Orders', permission: 'Create Sale Orders', permission_code: 'add_sale_orders' },
  { source: 'Sales & Orders', permission: 'Record POS Sales', permission_code: 'add_pos_sales' },
  { source: 'Sales & Orders', permission: 'Edit/Update Orders', permission_code: 'edit_update_orders' },
  { source: 'Sales & Orders', permission: 'Edit/Update Sales', permission_code: 'edit_update_sales' },
  { source: 'Sales & Orders', permission: 'Record Sale Payments', permission_code: 'add_sales_payments' },
  { source: 'Services', permission: 'Access Services', permission_code: 'access_services' },
  { source: 'Services', permission: 'Add Services', permission_code: 'add_services' },
  { source: 'Services', permission: 'Edit/Update Services', permission_code: 'edit_update_services' },
  { source: 'Subscriptions', permission: 'Access Subscriptions', permission_code: 'access_subscriptions' },
  { source: 'Subscriptions', permission: 'Edit/Update Subscriptions', permission_code: 'edit_update_subscriptions' },
  { source: 'Team Members', permission: 'Access Team Members', permission_code: 'access_team_members' },
  { source: 'Team Members', permission: 'Add Team Members', permission_code: 'add_team_members' },
  { source: 'Team Members', permission: 'Edit/Update Team Members', permission_code: 'edit_update_team_members' }
];

export const teamMemberRolePermissions = (role) => {
  switch (role) {
    case "manager":
      return [
        'access_overview',
        'access_dashboard',
        'access_analytics',
        'record_sales',
        'add_sales_payments',
        'add_pos_sales',
        'add_sale_orders',
        'edit_update_sales',
        'access_sales',
        'access_sales_payment',
        'access_inventory',
        'add_products',
        'edit_update_products',
        'access_services',
        'add_services',
        'edit_update_services',
        'access_expenses',
        'add_expenses',
        'edit_update_expenses',
        'access_team_members',
        'add_team_members',
        'edit_update_team_members',
        'access_payouts',
        'access_business_settings',
        'edit_update_business_settings',
        'access_customers',
        'add_customers',
        'edit_update_customers',
        'message_customers',
        'access_subscriptions',
        'edit_update_subscriptions',
        'access_raw_materials',
        'add_raw_materials',
        'edit_update_raw_materials',
        'access_orders',
        'add_orders',
        'edit_update_orders',
        'access_storefront',
        'access_purchase_orders',
        'add_purchase_orders',
        'edit_update_purchase_orders',
        'access_suppliers',
        'add_suppliers',
        'edit_update_suppliers',
        'access_damages_loss',
        'add_damages_loss',
        'edit_update_damages_loss',
      ];

    case "sales_representative":
      return [
        'access_overview',
        'access_dashboard',
        'record_sales',
        'edit_update_sales',
        'access_sales',
        'add_sales_payments',
        'add_pos_sales',
        'add_sale_orders',
        'access_inventory',
        'access_customers',
        'add_customers',
        'edit_update_customers',
        'message_customers',
        'access_orders',
        'add_orders',
        'edit_update_orders',
      ];

    case "accountant":
      return [
        'access_overview',
        'access_dashboard',
        'access_analytics',
        'access_sales',
        'access_sales_payment',
        'access_inventory',
        'access_raw_materials',
        'access_purchase_orders',
        'access_suppliers',
        'access_expenses',
        'add_expenses',
        'edit_update_expenses',
        'access_damages_loss',
      ];

    case "customer_service_representative":
      return [
        'access_customers',
        'add_customers',
        'edit_update_customers',
        'message_customers',
      ];

    default:
      return [
        'access_overview',
        'access_dashboard',
        'access_analytics',
        'record_sales',
        'edit_update_sales',
        'access_sales',
        'add_sales_payments',
        'add_pos_sales',
        'add_sale_orders',
        'access_inventory',
        'access_customers',
        'add_customers',
        'edit_update_customers',
        'message_customers',
        'access_orders',
        'add_orders',
        'edit_update_orders',
      ];
  }
}

export const getTeamMemberRoleByName = (role) => {

  switch (role) {
    case 'manager':
      return {
        role: 'Manager',
        description: 'The Manager role grants access to oversee all aspects of the business, including sales, inventory, expenses, and customer management, ensuring smooth operations and effective decision-making.',
      };

    case 'sales_representative':
      return {
        role: 'Sales Representative',
        description: 'Sales Representatives focus on generating revenue by managing sales activities, accessing customer information, and processing orders to drive business growth and meet sales targets.',
      };

    case 'accountant':
      return {
        role: 'Accountant/Bookkeeper',
        description: 'Accountants/Bookkeepers handle financial transactions and reporting, managing expenses, sales, and inventory to ensure accurate financial records and compliance with accounting standards.',
      };

    case 'customer_service_representative':
      return {
        role: 'Customer Service Representative',
        description: 'Customer Service Representatives provide assistance and support to customers, managing customer inquiries, messages, and orders to ensure high levels of satisfaction and retention.',
      };

    default:
      return {
        role: 'Sales Representative',
        description: 'Sales Representatives focus on generating revenue by managing sales activities, accessing customer information, and processing orders to drive business growth and meet sales targets.',
      };
  }
  
}

export const createSubdomain = (businessName) => {
  // Remove whitespace and special characters from the business name
  const formattedName = businessName.replace(/[^\w]/g, '');

  // Convert the business name to lowercase
  const lowerCaseName = formattedName.toLowerCase();

  // Truncate the subdomain to 8 characters
  const truncatedSubdomain = lowerCaseName.slice(0, 8);

  return truncatedSubdomain;
}

export const isValidDomain = (domain) => {
  // Regular expression pattern for domain validation
  const domainRegex = /^(?!:\/\/)([a-zA-Z0-9-_]{1,63}\.)+([a-zA-Z]{2,}|xn--[a-zA-Z0-9]{2,})$/;
  return domainRegex.test(domain);
}

export const validateSubdomain = (value) => {
  const subdomainRegex = /^[a-zA-Z][a-zA-Z0-9-]{1,20}$/; // Regex to match subdomains with letters, numbers, and hyphens, with a maximum length of 8 characters
  if (value && !subdomainRegex.test(value)) {
    return Promise.reject('Subdomain is invalid. Subdomain should contain letters, numbers, and hyphens (no spaces), with a maximum length of 20 characters.');
  }
  return Promise.resolve();
}

export const validateURL = (value) => {
  const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
  if (value && !urlRegex.test(value)) {
    return Promise.reject('URL is invalid');
  }
  return Promise.resolve();
}

export const validateNoZero = (value) => {
  if (value === 0) {
    return Promise.reject('amount cannot be 0');
  }
  return Promise.resolve();
}

export const getRouteFromUrl = (url) => {
  const parsedUrl = new URL(url);
  return `${parsedUrl.pathname}${parsedUrl.search}`;
}

export const getNotificationIcons = (notification_type) => {
  switch (notification_type) {
    case 'inventory':
      return {icon: 'package', color: 'text-danger', bg_color: 'bg-danger-dim'}
    
    case 'sales':
      return {icon: 'coins', color: 'text-success', bg_color: 'bg-success-dim'}

    case 'orders':
      return {icon: 'cart', color: 'text-warning', bg_color: 'bg-warning-dim'}

    case 'customers':
      return {icon: 'thumbs-up', color: 'text-primary', bg_color: 'bg-primary-dim'}

    case 'payouts':
      return {icon: 'wallet-saving', color: 'text-success', bg_color: 'bg-success-dim'}
  
    default:
      return {icon: 'target', color: 'text-primary', bg_color: 'bg-primary-dim'}
  }
}

export const getDateAgo = (date) => {
  const now = moment(); // current time
  const targetDate = moment(date);
  const diffDays = now.diff(targetDate, 'days'); // calculate difference in days

  if (diffDays <= 3) {
    // If the date is 3 days old or less, show relative time
    return targetDate.fromNow(); // "2 days ago", "a day ago", etc.
  } else {
    // If the date is more than 3 days old, show specific format
    return targetDate.format('Do MMM YYYY'); // "20th Oct 2020", etc.
  }
}

export const generateVariants = (variantTypes, variants, productName, defaultCostPrice, defaultSellingPrice) => {
  function helper(index, currentVariant, result) {
    if (index === variantTypes.length) {
      const variantName = currentVariant.map(v => v.option).join(" / ");

      // Check if the current variant combination already exists by comparing each type and option
      const existingVariant = variants.find((v) => 
        v.variant_type.length === currentVariant.length &&
        v.variant_type.every((vt, i) => 
          vt.type === currentVariant[i].type && vt.option === currentVariant[i].option
        )
      );

      if (existingVariant) {
        // If the variant exists, update the product_name
        existingVariant.product_name = `${productName} - (${variantName})`;
        result.push(existingVariant);
      } else {
        // If the variant doesn't exist, add it
        result.push({
          product_id: null,
          variant_type: currentVariant,
          cost_price: defaultCostPrice,
          selling_price: defaultSellingPrice,
          quantity: 0,
          product_name: `${productName} - (${variantName})`
        });
      }
      return;
    }

    const { type, options } = variantTypes[index];
    for (const option of options) {
      // Build the current variant in the desired format
      helper(index + 1, [...currentVariant, { type: type, option: option }], result);
    }
  }

  // Initialize result array
  const result = [];
  helper(0, [], result);
  return result;
};


export const sumVariantTotalQuantity = (variants) => {
  return variants.reduce((total, variant) => total + variant.quantity, 0);
}