import Dexie from 'dexie';

// Define your Dexie database
const offlineDB = new Dexie('kusOfflineDB');

// Define the schema for your database
offlineDB.version(1).stores({
  customers: '[business_id+customer_id], customer_code, customer_name, customer_phone_number, customer_email', 
  products: '[business_id+product_id], product_name, selling_price, cost_price, thumbnail, quantity, stock_status, restock_level, barcode, SKU, category',
  services: '[business_id+service_id], service_name, selling_price',
  locations: '[business_id+location_id], location_name, location_type, landmark, cost, lat, lng',
  tax_profiles: '[business_id+tax_id], tax_profile_name, taxes',
  offline_sales: '++id, business_id, customer_name, phone_number, customer_email, items, fulfillment_type, location_name, location, lat, lng, fulfillment_cost, discount_type, discount_amount, tax_profile, note, issue_date, due_date, payment_type, amount_received, alert_customer, sale_type',
  offline_sale_balance_paid: '++id, business_id, sale_id, is_offline_sale, payment_type, date, amount_paid, alert_customer',
  sale_settings: '&business_id, inventory_restriction, service_restriction'
});

// Open the database
offlineDB.open().catch((err) => {
  console.error('Failed to open database:', err.stack || err);
});

const getCustomers_OffDB = async (businessID, customerName) => {
  if (customerName) {
    // Fetch customers where customer_name or phone_number starts with the input (case-insensitive)
    return offlineDB.customers
      .filter(customer =>
        customer.business_id === businessID && (
          customer.customer_name?.toLowerCase()?.includes(customerName.toLowerCase()) ||
          customer.customer_phone_number?.includes(customerName)
        )
      )
      .toArray();
  } else {
    // If no search query, return all customers
    return offlineDB.customers
    .where("business_id")
    .equals(businessID)
    .toArray();
  }
};

const updateCustomers_OffDB = async (customers) => {
  try {
    // Use bulkPut to update or add customers based on the 'customer_id' field
    await offlineDB.customers.bulkPut(customers);
    console.log("Customers have been added or updated");
  } catch (error) {
    console.error("Error updating or adding customers:", error);
  }
};

const getProducts_OffDB = async (businessID, productName) => {
  if (productName) {
    // Fetch products where product name or barcode or sku starts with the input (case-insensitive)
    return offlineDB.products
      .filter(product =>
        product.business_id === businessID && 
        product.product_name?.toLowerCase()?.includes(productName.toLowerCase())        
      )
      .toArray();
  } else {
    // If no search query, return all products
    return offlineDB.products
    .where("business_id")
    .equals(businessID).toArray();
  }
};

const updateProducts_OffDB = async (products) => {
  try {
    // Use bulkPut to update or add products based on the 'product_id' field
    await offlineDB.products.bulkPut(products);
    console.log("Products have been added or updated");
  } catch (error) {
    console.error("Error updating or adding products:", error);
  }
};

const reduceProductQuantities = async (items, businessID) => {
  try {
    // Use a transaction for the products store
    await offlineDB.transaction('rw', offlineDB.products, async () => {
      for (const item of items) {
        // Skip items that are not products
        if (item.item_type !== 'product') {
          console.log(`Skipping item ${item.item_name} as it is not a product.`);
          continue;
        }

        // Get the product from the database
        const product = await offlineDB.products.get([businessID, item.item_id]);

        if (product) {
          // Calculate the new quantity
          const newQuantity = Math.max(product.quantity - item.quantity, 0);

          // Determine the new stock status
          const newStockStatus = newQuantity === 0 ? "out_of_stock" : product.stock_status;

          // Update the product's quantity and stock status
          await offlineDB.products.update([businessID, item.item_id], {
            quantity: newQuantity,
            stock_status: newStockStatus,
          });

          console.log(
            `Updated product ${product.product_name}: new quantity is ${newQuantity}, stock status is ${newStockStatus}`
          );
        } else {
          console.warn(`Product with ID ${item.item_id} not found in database.`);
        }
      }
    });

    console.log('Product quantities and stock statuses updated successfully.');
  } catch (error) {
    console.error('Failed to update product quantities and stock statuses:', error);
  }
}

const returnProductQuantities = async (items, businessID) => {
  try {
    // Use a transaction for the products store
    await offlineDB.transaction('rw', offlineDB.products, async () => {
      for (const item of items) {
        // Skip items that are not products
        if (item.item_type !== 'product') {
          console.log(`Skipping item ${item.item_name} as it is not a product.`);
          continue;
        }

        // Get the product from the database
        const product = await offlineDB.products.get([businessID, item.item_id]);

        if (product) {
          // Restore the quantity
          const restoredQuantity = product.quantity + item.quantity;

          // Determine the new stock status
          const restoredStockStatus = restoredQuantity > 0 ? "in_stock" : product.stock_status;

          // Update the product's quantity and stock status
          await offlineDB.products.update([businessID, item.item_id], {
            quantity: restoredQuantity,
            stock_status: restoredStockStatus,
          });

          console.log(
            `Restored product ${product.product_name}: quantity is now ${restoredQuantity}, stock status is ${restoredStockStatus}`
          );
        } else {
          console.warn(`Product with ID ${item.item_id} not found in database.`);
        }
      }
    });

    console.log('Product quantities restored successfully.');
  } catch (error) {
    console.error('Failed to restore product quantities:', error);
  }
}

const getServices_OffDB = async (businessID, serviceName) => {
  if (serviceName) {
    // Fetch service where service name 
    return offlineDB.services
      .filter(service =>
        service.business_id === businessID && 
        service.service_name?.toLowerCase()?.includes(service.toLowerCase())        
      )
      .toArray();
  } else {
    // If no search query, return all services
    return offlineDB.services
    .where("business_id")
    .equals(businessID)
    .toArray();
  }
};

const updateServices_OffDB = async (services) => {
  try {
    // Use bulkPut to update or add services based on the 'service_id' field
    await offlineDB.services.bulkPut(services);
    console.log("Services have been added or updated");
  } catch (error) {
    console.error("Error updating or adding services:", error);
  }
};

const getLocations_OffDB = async (businessID, locationName, locationType) => {
  if (locationName) {
    // Fetch location where location name 
    return offlineDB.locations
      .filter(location =>
        location.business_id === businessID && 
        location.location_type === locationType && 
        location.location_name?.toLowerCase()?.includes(locationName.toLowerCase())        
      )
      .toArray();
  } else {
    // If no search query, return all locations
    return offlineDB.locations
    .where("business_id")
    .equals(businessID)
    .toArray();
  }
};

const updateLocations_OffDB = async (locations) => {
  try {
    // Use bulkPut to update or add locations based on the 'location_id' field
    await offlineDB.locations.bulkPut(locations);
    console.log("Locations have been added or updated");
  } catch (error) {
    console.error("Error updating or adding locations:", error);
  }
};

const getSalesSettings_OffDB = async (businessID) => {
  return offlineDB.sale_settings
    .where("business_id")
    .equals(businessID)
    .toArray();
};

const updateSalesSettings_OffDB = async (settings) => {
  try {
    // Use bulkPut to update or add sales settings based on the 'business_id' field
    await offlineDB.sale_settings.bulkPut(settings);
    console.log("Sales settings have been added or updated");
  } catch (error) {
    console.error("Error updating or adding sales settings:", error);
  }
};

const getTaxProfiles_OffDB = async (businessID) => {
  return offlineDB.tax_profiles
  .where("business_id")
  .equals(businessID)
  .toArray();
};

const updateTaxProfiles_OffDB = async (taxProfiles) => {
  try {
    // Use bulkPut to update or add tax profiles based on the 'business_id' field
    await offlineDB.tax_profiles.bulkPut(taxProfiles);
    console.log("Tax profiles have been added or updated");
  } catch (error) {
    console.error("Error updating or adding tax profiles:", error);
  }
};

const addOfflineSaleBalance = async (balanceInfo) => {
  try {
    const id = await offlineDB.offline_sale_balance_paid.add(balanceInfo);
    console.log("Offline sale balance added successfully");
    return id;
  } catch (error) {
    console.error("Error adding offline sale balance:", error);
    return false;
  }
};

const getOfflineSaleBalance = async (saleID) => {
  try {
    const payments = await offlineDB.offline_sale_balance_paid
      .where("sale_id")
      .equals(saleID)
      .toArray();
    return payments;
  } catch (error) {
    console.error("Failed to retrieve payments:", error);
    return [];
  }
}

const deleteOfflineSaleBalanceBySaleId = async (saleID) => {
  try {
    // Retrieve all entries with the specified sale_id
    const paymentsToDelete = await offlineDB.offline_sale_balance_paid
      .where("sale_id")
      .equals(saleID)
      .toArray();

    // Extract the primary keys (ids) of the entries to delete
    const paymentIds = paymentsToDelete.map(payment => payment.id);

    // Delete the matched entries by their primary keys
    if (paymentIds.length > 0) {
      await offlineDB.offline_sale_balance_paid.bulkDelete(paymentIds);
      console.log("Offline sale balances deleted successfully for sale_id:", saleID);
    } else {
      console.log("No matching balances found for the provided sale_id.");
    }
  } catch (error) {
    console.error("Error deleting offline sale balances by sale_id:", error);
  }
};


const addOfflineSale = async (saleInfo) => {
  try {
    const id = await offlineDB.offline_sales.add(saleInfo);
    console.log("Offline sale added successfully");
    return id;
  } catch (error) {
    console.error("Error adding offline sale:", error);
  }
};

const updateOfflineSale = async (id, saleInfo) => {
  try {
    await offlineDB.offline_sales.update(id, saleInfo);
    console.log("Offline sale updated successfully");
  } catch (error) {
    console.error("Error updating offline sale:", error);
  }
};

const getOfflineSale = async (saleID) => {
  try {
    const sale = await offlineDB.offline_sales.get(saleID);
    if (sale) {
      console.log("Offline sale found:", sale);
      return sale;
    } else {
      console.log("No offline sale found with the given ID");
    }
  } catch (error) {
    console.error("Error getting offline sale:", error);
  }
};

const deleteOfflineSale = async (saleID) => {
  try {
    // Retrieve the sale before deletion
    const sale = await offlineDB.offline_sales.get(saleID);

    if (!sale) {
      console.error(`Sale with ID ${saleID} not found.`);
      return;
    }

    // Extract the businessID from the sale
    const businessID = sale.business_id;

    // Parse the items stored as a JSON string
    let items = [];
    if (sale.items) {
      try {
        items = JSON.parse(sale.items);
      } catch (error) {
        console.error("Failed to parse items JSON:", error);
      }
    }

    await offlineDB.offline_sales.delete(saleID);
    await deleteOfflineSaleBalanceBySaleId(saleID);

    if (items.length > 0) {
      await returnProductQuantities(items, businessID);
    } else {
      console.log("No items found in the sale to restore quantities.");
    }

    console.log("Offline sale deleted successfully");
  } catch (error) {
    console.error("Error deleting offline sale:", error);
  }
};

const deleteOfflineSalesByIds = async (saleIds) => {
  try {
    // Check if saleIds is an array and has at least one ID
    if (Array.isArray(saleIds) && saleIds.length > 0) {

      // Retrieve all the sales before deletion
      const sales = await offlineDB.offline_sales.where('id').anyOf(saleIds).toArray();

      // Process each sale to extract items and businessID
      for (const sale of sales) {
        const businessID = sale.business_id;

        // Parse the items stored as a JSON string
        let items = [];
        if (sale.items) {
          try {
            items = JSON.parse(sale.items);
          } catch (error) {
            console.error(`Failed to parse items JSON for sale ID ${sale.id}:`, error);
          }
        }

        // Return the product quantities for each sale
        if (items.length > 0) {
          await returnProductQuantities(items, businessID);
        } else {
          console.log(`No items found in sale ID ${sale.id} to restore quantities.`);
        }
      }

      // Use bulkDelete to remove multiple records by IDs
      await offlineDB.offline_sales.bulkDelete(saleIds);
      await deleteOfflineSaleBalancesBySaleIds(saleIds);
      console.log("Offline sales deleted successfully:", saleIds);
    } else {
      console.log("No valid IDs provided for deletion.");
    }
  } catch (error) {
    console.error("Error deleting offline sales by IDs:", error);
  }
};

const deleteOfflineSaleBalancesBySaleIds = async (saleIds) => {
  try {
    // Check if saleIds is a valid array and has at least one ID
    if (Array.isArray(saleIds) && saleIds.length > 0) {
      // Retrieve all entries with a sale_id in saleIds array
      const balancesToDelete = await offlineDB.offline_sale_balance_paid
        .where('sale_id')
        .anyOf(saleIds)
        .toArray();

      // Extract the primary keys (ids) of the balances to delete
      const balanceIds = balancesToDelete.map(balance => balance.id);

      // Delete the matched entries by their primary keys
      if (balanceIds.length > 0) {
        await offlineDB.offline_sale_balance_paid.bulkDelete(balanceIds);
        console.log("Offline sale balances deleted successfully for sale_ids:", saleIds);
      } else {
        console.log("No matching balances found for provided sale_ids.");
      }
    } else {
      console.log("No valid sale_ids provided for deletion.");
    }
  } catch (error) {
    console.error("Error deleting offline sale balances by sale_ids:", error);
  }
};

const deleteOfflineDB = async () => {
  try {
    await offlineDB.delete();
    console.log("Offline database Deleted.");
  } catch (error) {
    console.error("Error: Failed to delete the offline database:", error);
  }
};


export {
  offlineDB,
  getCustomers_OffDB,
  updateCustomers_OffDB,
  getProducts_OffDB,
  updateProducts_OffDB,
  reduceProductQuantities,
  getServices_OffDB,
  updateServices_OffDB,
  getLocations_OffDB,
  updateLocations_OffDB,
  getSalesSettings_OffDB,
  updateSalesSettings_OffDB,
  getTaxProfiles_OffDB,
  updateTaxProfiles_OffDB,
  addOfflineSale,
  updateOfflineSale,
  getOfflineSale,
  deleteOfflineSale,
  deleteOfflineSalesByIds,
  deleteOfflineDB,
  addOfflineSaleBalance,
  getOfflineSaleBalance
};
