import React, { useState, useEffect } from "react";
import Content from "../layout/content/Content";
import Head from "../layout/head/Head";
import { Card, Spinner, Alert, 
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import {
  Block,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  BackTo,
  Icon,
  Button,
  Row,
  Col,
  PreviewAltCard,
} from "../components/Component";
import { Empty } from 'antd';
import { baseURLs } from '../utils/Constants';
import { formatTo2DP, getAxiosHeaders, getQueryParams, moneyToFloat } from "../utils/Utils";
import { Link } from "react-router-dom";
import classNames from "classnames";
import axios from 'axios';
import moment from "moment";
import Header from "./components/sale/Header";
import { SuccessModal } from "./components/AlertModals";
import { getOfflineSale, getOfflineSaleBalance, getTaxProfiles_OffDB } from "../utils/OfflineDB";
import OfflineBody from "./components/sale/OfflineBody";
import { Numeral } from "react-numeral";

const OfflineSaleDetails = ({ history, match }) => {
  const [sm, updateSm] = useState(false);
  const [loading, setLoading] = useState(true);
  const [businessID, setBusinessID] = useState("");
  const [businessInfo, setBusinessInfo] = useState({});
  const [saleDetails, setSaleDetails] = useState({
    business_id: '',
    business_logo: '',
    business_name: '',
    business_location: '',
    business_phone_number: '',
    business_country_iso: '',
    currency: '',
    sale_id: '',
    sale_number: '', 
    status: '', 
    customer_uuid: '',
    customer_name: '', 
    customer_phone_number: '', 
    customer_email: '', 
    issued_by: '', 
    viewed: '', 
    viewed_at: '', 
    issued_date: '', 
    due_date: '', 
    created_at: '', 
    updated_at: '', 
    items: [], 
    delivery_pickup: null,
    payment_log: [], 
    order_log: [], 
    sub_total: '', 
    tax_amount: '', 
    taxes: [], 
    discount: {
      discount_type: 'fixed',
      discount_amount: '0.00',
      discount_percentage: '0',
    }, 
    total_amount: '', 
    amount_paid: '', 
    balance_due: '', 
    amount_received: '', 
    change: '', 
    sale_origin: '',
    feedback: {
      rate: null,
      message: null,
    },
    note: '',
    customer_note: '',
    share_link: '',
    a4_print_link: '',
    thermal_print_link: '',
    outstanding_balance: {
      meta: {
        total_records: 0,
        total_outstanding_balance_due: 0,
        sales: []
      }
    },
    team_members: []
  });
  const [headerColor, setHeaderColor] = useState("");
  const [saleNumber, setSaleNumber] = useState("...");
  const [completedStatus, setCompletedStatus] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [activeModal, setActiveModal] = useState(null);

  const toggleModal = (modal) => {
    if (activeModal === modal) {
      setActiveModal(null);
    } else {
      setActiveModal(modal);
    }
  };

  const getSaleDetails = async (businessInfo, saleID, printSale = 'no') => {
    setLoading(true);

    let saleInfo = await getOfflineSale(saleID);
    if (!saleInfo || saleInfo?.length === 0) {
      setErrorMessage('Sale not found');
      setLoading(false);
      return;
    }

    let totalAmountPaid = parseFloat(saleInfo.amount_received);
    let saleBalancePayment = await getOfflineSaleBalance(saleID);
    
    if(saleBalancePayment.length > 0) {
      const totalBalancePaid = saleBalancePayment.reduce((total, payment) => total + parseFloat(payment.amount_paid), 0);
      totalAmountPaid = totalAmountPaid + totalBalancePaid;
    }

    let saleItems = JSON.parse(saleInfo.items) || [];
    saleItems = saleItems.map(item => {
      return {
          ...item,
          total: formatTo2DP(item.price * item.quantity)
      };
    });

    let taxes = [];
    let taxProfiles = await getTaxProfiles_OffDB(businessInfo.business_id);
    if(taxProfiles.length > 0) {
      taxes = taxProfiles.filter((profile) => profile.tax_id == saleInfo.tax_profile)
    }

    let saleSummary = getSaleSummary(
      saleItems,
      saleInfo.fulfillment_cost,
      saleInfo.discount_amount,
      saleInfo.discount_type,
      totalAmountPaid,
      taxes,
      saleInfo.sale_type
    );

    let _saleDetails = saleDetails;
    _saleDetails.business_id = businessInfo.business_id
    _saleDetails.business_logo = businessInfo.logo
    _saleDetails.business_name = businessInfo.name
    _saleDetails.business_location = businessInfo.location
    _saleDetails.business_phone_number = businessInfo.phone_number
    _saleDetails.currency = businessInfo.currency
    _saleDetails.business_country_iso = businessInfo.country_iso

    _saleDetails.sale_id = saleID
    _saleDetails.sale_number = `OFF-${saleID}`;
    _saleDetails.status = saleSummary.status;
    _saleDetails.customer_uuid = '';
    _saleDetails.customer_name = saleInfo.customer_name?.length === 0 ? 'Customer' : saleInfo.customer_name;
    _saleDetails.customer_phone_number = saleInfo.phone_number;
    _saleDetails.customer_email = saleInfo.customer_email;
    _saleDetails.issued_by = '(You)';
    _saleDetails.issued_date = saleInfo.issued_date;
    _saleDetails.due_date = saleInfo.due_date;
    _saleDetails.created_at = saleInfo.created_at;
    _saleDetails.updated_at = saleInfo.updated_at;
    _saleDetails.items = saleItems;
    // _saleDetails.payment_log = saleInfo.payment_log.length > 1 ? saleInfo.payment_log.reverse() : saleInfo.payment_log;
    
    if(saleInfo.location_name) {
      _saleDetails.delivery_pickup = {
        location_type: saleInfo.fulfillment_type,
        location_address: saleInfo.location,
        location_landmark: saleInfo.location_name,
        location_lat: saleInfo.lat,
        location_lng: saleInfo.lng,
        location_cost: saleInfo.fulfillment_cost,
      };

    }

    _saleDetails.sub_total = saleSummary.sub_total;
    _saleDetails.tax_amount = saleSummary.total_tax;
    _saleDetails.taxes = saleSummary.taxes;
    _saleDetails.discount = {
      discount_amount: saleSummary.discount_amount,
      discount_percentage: saleSummary.discount_percentage,
      discount_type: 'percentage'
    };
    _saleDetails.total_amount = saleSummary.total_amount;
    _saleDetails.amount_paid = saleSummary.amount_paid;
    _saleDetails.balance_due = saleSummary.balance_due;
    _saleDetails.change = saleSummary.change;
    _saleDetails.amount_received = saleSummary.amount_received;
    // _saleDetails.sale_origin = saleInfo.sale_origin;
    // _saleDetails.note = saleInfo.note;
    // _saleDetails.a4_print_link = saleInfo.a4_print_link;
    // _saleDetails.thermal_print_link = saleInfo.thermal_print_link;

    
    setSaleNumber(`OFF-${saleID}`);
    setSaleDetails({..._saleDetails});
    setHeaderColor(businessInfo.brand_color);
    setLoading(false);

    if(printSale === 'yes') {
      setTimeout(() => {
        thermalPrintSale();
      }, 1000);
    }
  }

  const getSaleSummary = (items, fulfillment_cost, discount_amount, discount_type, amount_received, taxes, sale_status) => {
    items = items || [];
    let fulfillmentCost = formatTo2DP(fulfillment_cost || 0);
    let discountAmount = formatTo2DP(discount_amount || 0);
    const discountType = discount_type || '';
    let amountReceived = formatTo2DP(amount_received || 0);

    let calculatedTaxAmounts = [];
    
    // Calculate Subtotal including Fulfillment Cost
    let subTotal = items.reduce((total, item) => {
      const price = item.price || 0;
      const quantity = item.quantity || 0;
      return total + price * quantity;
    }, 0) + formatTo2DP(fulfillmentCost);
    
    // Calculate Direct Taxes on Subtotal including Fulfillment Cost
    let directTaxesTotal = 0;
    taxes.forEach(tax => {
      if (tax.type === 'direct') {
        let taxAmount = formatTo2DP((subTotal * tax.percentage) / 100);
        calculatedTaxAmounts.push({
          tax: tax.tax_name,
          percentage: tax.percentage,
          amount: taxAmount,
          type: 'direct',
        });
        
        directTaxesTotal += taxAmount;
        
      }
    });
  
    // Calculate Subtotal with Direct Taxes and Fulfillment Cost
    let subTotalWithDirectTaxes = formatTo2DP(subTotal + directTaxesTotal);
  
    // Calculate Compound Taxes on Subtotal with Direct Taxes and Fulfillment Cost
    let compoundTaxesTotal = 0;
    taxes.forEach(tax => {
      if (tax.type === 'compound') {
        let taxAmount = formatTo2DP((subTotalWithDirectTaxes * tax.percentage) / 100);
        calculatedTaxAmounts.push({
          tax: tax.tax_name,
          percentage: tax.percentage,
          amount: taxAmount,
          type: 'compound',
        });

        compoundTaxesTotal += taxAmount;
      }
    });
  
    // Calculate Total Tax Amount
    let totalTaxAmount = formatTo2DP(directTaxesTotal + compoundTaxesTotal);
  
    // Calculate Total Amount including Taxes, Fulfillment Cost, and Discounts
    let totalAmount = formatTo2DP(subTotalWithDirectTaxes + compoundTaxesTotal);
    
    let discountPercentage = 0;
    if(totalAmount !== 0){
      if (discountType === 'fixed') {
        discountPercentage = formatTo2DP((discountAmount * 100) / totalAmount);
        totalAmount -= formatTo2DP(discountAmount); // Deduct fixed discount amount
      } else if (discountType === 'percentage') {
        discountPercentage = discountAmount;
        discountAmount = formatTo2DP((totalAmount * discountAmount) / 100);
        totalAmount -= discountAmount; // Deduct discount percentage
      }
    }

    // Calculate balance due
    let balanceDue = formatTo2DP(totalAmount - amountReceived);
    let amountPaid = totalAmount >= amountReceived ? amountReceived : totalAmount;
    let saleStatus = sale_status;

    if(totalAmount <= amountPaid){
      saleStatus = 'receipt';
    } else if(amountPaid > 0) {
      saleStatus = 'invoice';
    }

    // Update sale summary state
    return {
      sub_total: subTotal,
      total_direct_tax: directTaxesTotal,
      total_compound_tax: compoundTaxesTotal,
      total_tax: totalTaxAmount,
      taxes: calculatedTaxAmounts,
      discount_amount: discountAmount,
      discount_percentage: discountPercentage,
      total_amount: totalAmount,
      amount_received: amountReceived,
      amount_paid: amountPaid,
      balance_due: balanceDue < 0 ? 0 : balanceDue,
      change: balanceDue < 0 ? balanceDue * -1 : 0,
      status: saleStatus
    };
  }

  const updateSales = (saleID) => {
    getSaleDetails(businessInfo, saleID);
  }

  const teamMembers = (business_id) => {
    setLoading(true);
    axios.get(baseURLs.API_URL + `/teams/permissions/edit_update_orders`, {
      params: {
        business_id: business_id
      },
      headers: getAxiosHeaders().headers
    })
    .then((response) => {
      let responseInfo = response.data;
      let teamMembers = responseInfo.data.team_members;
      let _saleDetails = saleDetails;

      _saleDetails.team_members = teamMembers;

      setSaleDetails({..._saleDetails});
    }).catch((error) => {
      console.log(error);
    });
  }

  const thermalPrintSale = () => {
    const divContents = document.getElementById("print-box").innerHTML;
    const printWindow = window.open('', '_blank'); // Opens in the same window/tab
  
    printWindow.document.write('<html>');
    printWindow.document.write('<head>');
    printWindow.document.write(`<title>Sale Print</title>`);
    // Injecting the custom CSS directly
    printWindow.document.write(`
      <style>
        table { width: 100%; }
        tr { width: 100%; }
        header { width: 100%; text-align: center; -webkit-align-content: center; align-content: center; vertical-align: middle; }
        .items thead { text-align: center; }
        .bill-details td { font-size: 12px; }
        .items .heading { font-size: 12.5px; text-transform: uppercase; border-top: 1px solid black; margin-bottom: 4px; border-bottom: 1px solid black; vertical-align: middle; }
        .items thead tr th:first-child, .items tbody tr td:first-child { width: 47%; min-width: 47%; max-width: 47%; word-break: break-word; text-align: left; }
        .items td { font-size: 12px; text-align: right; vertical-align: top; }
        .price::before { font-family: Arial; text-align: right; }
        .sum-up { text-align: right !important; }
        .total { font-size: 13px; border-top: 1px dashed black !important; border-bottom: 1px dashed black !important; }
        .total.text, .total.price { text-align: right; }
        .line { border-top: 1px solid black !important; }
        .heading.rate { width: 20%; text-align: right; }
        .heading.amount { width: 25%; text-align: right; }
        .heading.qty { width: 5%; }
        .text-center { text-align: center; }
        .d-flex { display: flex; }
        .m-0 { margin: 0 !important; }
        .mt-1 { margin-top: 0.375rem !important; }
        .mb-1 { margin-bottom: 0.375rem !important; }
        .mt-2 { margin-top: 0.75rem !important; }
        p { padding: 1px; margin: 0; font-size: 14px; }
        section { font-size: 12px; }
        .w-20 { width: 20% !important; }
        .w-80 { width: 80% !important; }
        .fs-12px { font-size: 12px; }
        .fs-18px { font-size: 18px; }
        .line { height: 3px !important; opacity: 1; margin: 0; }
        .tr-gap { height: 10px; }
      </style>
    `);
    printWindow.document.write('</head>');
    printWindow.document.write('<body style="background: #ffffff;">');
    printWindow.document.write(divContents);
    printWindow.document.write('</body></html>');
    printWindow.document.close();
  
    setTimeout(() => {
      printWindow.print();
      printWindow.close();
    }, 800);
  };

  useEffect(() => {
    setLoading(true);
    let url_string = window.location.href;
    let url = new URL(url_string);
    let queryParams = getQueryParams(url);

    let isSaved = queryParams.hasOwnProperty('saved') ? queryParams.saved : '';
    let sentAlert = queryParams.hasOwnProperty('alerted') ? queryParams.alerted : '';
    let printSale = queryParams.hasOwnProperty('print') ? queryParams.print : '';

    const _businessID = parseInt(match.params.businessID, 10);
    const saleID = parseInt(match.params.saleID, 10);
    if ( Number.isInteger(_businessID) && Number.isInteger(saleID) ) {
      localStorage.setItem('current_business_id', _businessID);
      let businesses = JSON.parse(localStorage.getItem('my_businesses'));
      let currentBusiness = businesses?.find((business) => business.business_id == _businessID);
      
      if(currentBusiness == undefined || null || ""){
        history.push(`${process.env.PUBLIC_URL}/not-found`);
        return;
      }

      if(isSaved === 'yes'){
        setCompletedStatus(sentAlert === 'yes' ? `alerted`  : `saved`);
        toggleModal('completedModal')
      }


      setBusinessInfo({...currentBusiness});
      setBusinessID(_businessID);
      getSaleDetails(currentBusiness, saleID, printSale);
      teamMembers(_businessID);
    } else {
      history.push(`${process.env.PUBLIC_URL}/not-found`);
      return;
    }
  }, [match.params.business]);

  return (<React.Fragment>
    <Head title="Sale Details" />
    <Content>
    {
      loading ?
      <Block className="nk-block-middle nk-auth-body text-center wide-xs">
        <div className="inner-pre-loader">
          <Spinner  color="dark" />          
        </div>
      </Block>
      :
      <>
        <BlockHead className="mb-4" size="sm">
          <div className="nk-block-between">
            <BlockHeadContent>
              <BlockDes className="text-soft">
                <p>{businessInfo.name}</p>
              </BlockDes>
              <BlockTitle page tag="h3">
                <span>Sale</span>
                <span className="fw-normal ms-1">#{saleNumber}</span>
              </BlockTitle>
              
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand me-n1 ${sm ? "active" : ""}`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="more-v"></Icon>
                </Button>
                <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                  <ul className="nk-block-tools g-3">
                    <li>
                      <BackTo className="mb-3" history={history} link={`/sales/b/${businessID}`} icon="arrow-left" type="button">Go Back</BackTo>
                    </li>
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </div>
        </BlockHead>
        
        <Block className="wide-sm m-auto">
          {
            errorMessage ? 
            <Card className="card-bordered">
              <div className="card-inner">
                <div className="text-center m-5">
                    <div className="price-plan-media"><Empty image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg" imageStyle={{height: 60}} description={false} /></div>
                    <div className="price-plan-info">
                      <h5 className="title fw-normal">Sale not found</h5>
                      <span>The sale you are looking for could not be found.</span>
                      <p className="text-soft">If you need help, you can reach us on our <a href={baseURLs.LIVE_CHAT}>live chat</a>.</p>
                    </div>
                    <div className="price-plan-action">
                      <Link to={`${process.env.PUBLIC_URL}/sales/b/${businessID}`}>
                        <button className="btn btn-outline-primary">Go to Sales</button>
                      </Link>                    
                    </div>
                  </div>
              </div>
            </Card>
            :
            <>
              <div className="card card-bordered">
                <Header headerInfo={saleDetails} color={headerColor} verificationStatus={businessInfo.is_verified} verificationType={businessInfo.verification_type} />
                <OfflineBody saleInfo={saleDetails} setSaleInfo={setSaleDetails} view={"business"} updateSales={() => updateSales(saleDetails.sale_id)} thermalPrintSale={() => thermalPrintSale()} />
                { 
                  activeModal === "completedModal" &&
                  <SuccessModal showModal={true} toggleModal={() => toggleModal(null)}
                    headerText={`Saved Offline Successfully`} descriptionText={`The ${saleDetails.status} has been saved offline successfully.`} 
                  />
                }
              </div> 
              <Block className="d-none">
                <div id="print-box">
                  <div>
                    <header className="text-center">
                      <h5 className="mt-1 mb-1 fs-18px">{saleDetails.business_name}</h5>
                      <p className="fs-12px">{saleDetails.business_location}</p>
                      <p className="fs-12px">{saleDetails.business_phone_number}</p>
                    </header>
                    <hr style={{ border: "1px dotted" }} />
                    <div className="d-flex">
                      <h5 className="m-0 w-20">TO:</h5>
                      <div className="w-80">
                        <h5 style={{ margin: 0 }}>{saleDetails.customer_name}</h5>
                        <p className="fs-12px">{saleDetails.customer_phone_number}</p>
                      </div>
                    </div>
                    <hr style={{ border: "1px dotted" }} />
                    <table className="bill-details mt-2">
                      <tbody>
                        <tr>
                          <td style={{ textTransform: "capitalize" }}>
                            {saleDetails.status} No.#: <span>{saleDetails.sale_number}</span>
                          </td>
                          <td>
                            Date : <span>{moment(saleDetails.updated_at).format("Do MMM, YYYY h:mm a")}</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table className="items">
                      <thead>
                        <tr>
                          <th className="heading name">Item</th>
                          <th className="heading qty">Qty</th>
                          <th className="heading rate">Cost</th>
                          <th className="heading amount">Total</th>
                        </tr>
                      </thead>
                      <tbody className="item-list">
                        {
                          saleDetails.items.map((data, index) => {
                            return (
                            <>
                              <tr key={index}>
                                <td>{data.item_name}</td>
                                <td>{data.quantity}</td>
                                <td className="price"> 
                                  <Numeral value={data.price.toString()} format={"0,0.00"} />
                                </td>
                                <td className="price"> 
                                  <Numeral value={data.total.toString()} format={"0,0.00"} />
                                </td>
                              </tr>
                              <tr>
                                <td colSpan={4}>
                                  <hr style={{ border: "1px dotted" }} />
                                </td>
                              </tr>
                            </>
                            )
                          })
                        }
                        
                        {
                          saleDetails.delivery_pickup && 
                          <>
                            <tr>
                              <td colSpan={3}>
                                {
                                  saleDetails.delivery_pickup.location_type === 'delivery' ? `Delivery` : `Pick Up`
                                } - {saleDetails.delivery_pickup.location_address} - {saleDetails.delivery_pickup.location_landmark}</td>
                              <td><Numeral value={saleDetails.delivery_pickup.location_cost.toString()} format={"0,0.00"} /></td>
                            </tr>
                            <tr className="tr-gap" />
                          </>
                        }
                        <tr>
                          <td colSpan={2} className="sum-up line">
                            Subtotal
                          </td>
                          <td colSpan={2} className="line price">
                            <Numeral value={saleDetails.sub_total.toString()} format={"0,0.00"} />
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={2} className="sum-up">
                            Total Tax
                          </td>
                          <td colSpan={2} className="price">
                            <Numeral value={saleDetails.tax_amount.toString()} format={"0,0.00"} />
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={2} className="sum-up">
                            Discount
                          </td>
                          <td colSpan={2} className="price">
                          <Numeral value={saleDetails.discount.discount_amount.toString()} format={"0,0.00"} /> <small>({saleDetails.discount.discount_percentage}%)</small>
                          </td>
                        </tr>
                        <tr>
                          <th colSpan={2} className="total text">
                            Total
                          </th>
                          <th colSpan={2} className="total price">
                            <small>{saleDetails.currency}</small>
                            <Numeral value={saleDetails.total_amount.toString()} format={"0,0.00"} />
                          </th>
                        </tr>
                        <tr>
                          <td colSpan={2} className="sum-up">
                            Amount Received
                          </td>
                          <td colSpan={2} className="price">
                          <Numeral value={saleDetails.amount_received.toString()} format={"0,0.00"} />
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={2} className="sum-up">
                            Amount Paid
                          </td>
                          <td colSpan={2} className="price">
                          <Numeral value={saleDetails.amount_paid.toString()} format={"0,0.00"} />
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={2} className="sum-up">
                            Balance to Pay
                          </td>
                          <td colSpan={2} className="price">
                          <Numeral value={saleDetails.balance_due.toString()} format={"0,0.00"} />
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={2} className="sum-up">
                            Change
                          </td>
                          <td colSpan={2} className="price">
                          <Numeral value={saleDetails.change.toString()} format={"0,0.00"} />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <hr style={{ border: "1px dotted" }} />
                    <div className="sale-note" />
                    <section className="text-center">
                      <p className="text-center mt-2">---END---</p>
                    </section>
                  </div>
                </div>
              </Block>          
            </>
          }
          
        </Block>
      </>
    }
    </Content>
    
  </React.Fragment>)
}

export default OfflineSaleDetails;